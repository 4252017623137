Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";
exports.getFormTypeOptionsApiEndPoint = "/bx_block_custom_forms/form_types"
exports.getCustomFormApiEndPoint = "/bx_block_custom_forms/custom_forms/";

exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";
exports.customFormSubmissions="bx_block_custom_forms/custom_form_submissions"

exports.markAsDarft=0
exports.markAsPublished=2

exports.formSubmitMessage="Form submitted successfully with reference number : "
exports.formSaveMessage="Form saved successfully with application number : "
exports.formFaildMessage="Submission failed Please reload the page and try to submit the form again"

exports.markAsPublishedLabel="close"
exports.markAsDarftBtnLabel="Go to form history"
exports.faildBtnLabel="Try again"
// Customizable Area End