import React, { Component } from "react";
// Customizable Area Start
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Typography, Box } from "@material-ui/core";
import InputElement from "./InputElement";
import { FormikTouched, FormikErrors } from "formik";
import { CustomFormSection } from "./CustomForm.web";
// Customizable Area End


// Customizable Area Start
type FormTypes = {
  [sectionId: string]: {
    [fieldName: string]: string | number | boolean;
  };
}
interface FormSectionProps extends WithStyles<typeof styles> {
  handleSameAsApplicant: () => void,
  formFields: CustomFormSection
  errors: FormikErrors<FormTypes>;
  values: FormTypes
  touched: FormikTouched<FormTypes>;
  setFieldValue: (field: string, value: any) => void;
  isDisabled:any;
  resetSameAsApplicant?: () => void
}
// Customizable Area End

class FormSection extends Component<FormSectionProps> {

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { formFields, errors, touched, classes, values, handleSameAsApplicant, setFieldValue, isDisabled} = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <Box className={classes.formOuterContainer}>
        <Box className={classes.headingContainer}>
        <Typography className={classes.heading}>
          {formFields.attributes.section_name}
        </Typography>
        <Typography variant="h4" className={this.props.classes.description}>
          {formFields.attributes.description}
        </Typography>
        </Box>
        <Grid container spacing={4} className={classes.formInnerContainer}>
          {formFields.attributes.fields.data.map((fData) => {
            const sectionId = formFields.id
            const { fixed_filed_name, required, field_type, place_holder, field_name, disabled, row_count } = fData.attributes
            return (
              <InputElement
                key={`${sectionId}.${fixed_filed_name}`}
                fieldType={field_type}
                error={errors[sectionId]?.[fixed_filed_name]}
                label={field_name}
                fixedName={fixed_filed_name}
                name={`${sectionId}.${fixed_filed_name}`}
                touched={touched[sectionId]?.[fixed_filed_name]}
                required={required}
                rowCount={row_count}
                options={fData.attributes.values}
                values={values[sectionId]}
                placeholder={place_holder}
                disabled={disabled || isDisabled}
                handleSameAsApplicant={handleSameAsApplicant}
                setFieldValue={setFieldValue}
                resetSameAsApplicant={this.props.resetSameAsApplicant}
              />
            );
          })}
        </Grid>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = () =>
  createStyles({
    formOuterContainer: {
      padding: "24px 0",
      '@media (max-width: 1600px)': {
        padding: "20px 0",
      }
    },
    formInnerContainer: {
      padding: "24px",
      '@media (max-width: 1600px)': {
        padding: "20px",
      }
    },
    heading: {
      fontSize: "32px",
      fontWeight: 600,
      fontFamily: "Poppins, sans-serif",
      textAlign: "center",
      '@media (max-width: 1600px)': {
        fontSize: "24px",
      }
    },
    description: {
      fontSize: "24px",
      textAlign: "center",
      fontFamily: "Poppins, sans-serif",
      '@media (max-width: 1600px)': {
        fontSize: "18px",
      }
    },
    headingContainer:{
      marginBottom: "34px",
      '@media (max-width: 1600px)': {
        marginBottom: "30px",
      }
    }
  });

export default withStyles(styles)(FormSection);
// Customizable Area End
