import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from 'react'
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { generateString } from "../../landingpage/src/LandingPageController";
import { getStorageData,setStorageData } from "framework/src/Utilities";

interface OrgFormErrors {
  [key: string]: string | undefined;
  orgNameError?: string;
  orgAddressError?: string;
  orgTownError?: string;
  orgPinCodeError?: string;
  orgCountryError?: string;
  orgTelephoneNoError?: string;
  orgAlternateTelephoneNoError?: string;
  orgEmailIDError?: string;
  orgFaxNumberError?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  userId: any;
  userPwd: any;
  reTypePwd: any;
  telePhoneNo: any;
  altTelePhoneNo: any;
  emailId: any;
  captcha: any;
  userCaptcha: any;
  userIdError: any;
  userIdErrorMsg:any;
  userPwdError: any;
  reTypePwdError: any;
  telePhoneNoError: any;
  altTelePhoneNoError: any;
  emailIdError: any;
  emailIdErrorMsg:any;
  userCaptchaError: any;
  digitalImage: any;
  showImage: any;
  showPwd: boolean;
  showAltPwd: boolean;
  apiError: any;
  orgNextButtonDisable:any;
  registrationCompleted:boolean;
  registrationFail:boolean;
  disableUserButton:boolean;
  selectedApplicant: any;
  applicatTypeError: any;
  roleList:any;
  orgName: any;
  orgAddress: any;
  orgTown: any;
  orgPinCode: any;
  orgCountry: any;
  orgCountryName:any;
  orgTelephoneNo: any;
  orgAlternateTelephoneNo: any;
  orgEmailID: any;
  orgFaxNumber: any;
  orgNameError: any;
  orgAddressError: any;
  orgTownError: any;
  orgPinCodeError: any;
  orgCountryError: any;
  orgTelephoneNoError: any;
  orgAlternateTelephoneNoError: any;
  orgEmailIDError: any;
  orgFaxNumberError: any;
  imageName:any;
  addDigitalSignature: boolean;
  uploadIcon: boolean;
  verifiedDS: boolean;
  showFullMessage:boolean;
  pageLabel: boolean;
  regenrateCaptcha:number;
  otp: {
    modal:boolean;
    value:string[];
    message:string;
    isVerified:boolean;
    emailVerifyError:string,
    otpVerifyError:string
  },
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp | null;
  emailReg: RegExp | null;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;


  currentCountryCode: any;
  addUserDetailsApiId:any;
  roleListId: any;
  createOrgApiID:any;
  otpInputRefs=[] as any[];
  verifyEmailApiId:string = '';
  verifyOtpApiId:string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      userId: "",
      userPwd: "",
      reTypePwd: "",
      telePhoneNo: "",
      altTelePhoneNo: "",
      emailId: "",
      captcha: "",
      userCaptcha: "",
      userIdError: "",
      userIdErrorMsg:"User ID must be at least 5 characters, no more than 16 characters, and allows only Alphanumeric without space",
      userPwdError: "",
      reTypePwdError: "",
      telePhoneNoError: "",
      altTelePhoneNoError: "",
      emailIdError: "",
      emailIdErrorMsg:"",
      userCaptchaError: "",
      digitalImage: "",
      showImage: "",
      showPwd: false,
      showAltPwd: false,
      apiError: "",
      orgNextButtonDisable:false,
      registrationCompleted:false,
      registrationFail:false,
      disableUserButton:false,
      selectedApplicant:"",
      applicatTypeError:"",
      roleList:[],
      orgName: "",
      orgAddress: "",
      orgTown: "",
      orgPinCode: "",
      orgCountry: "",
      orgCountryName:"",
      orgTelephoneNo: "",
      orgAlternateTelephoneNo: "",
      orgEmailID: "",
      orgFaxNumber: "",
      orgNameError: "",
      orgAddressError: "",
      orgTownError: "",
      orgPinCodeError: "",
      orgCountryError: "",
      orgTelephoneNoError: "",
      orgAlternateTelephoneNoError: "",
      orgEmailIDError: "",
      orgFaxNumberError: "",
      addDigitalSignature: true,
      uploadIcon: false,
      verifiedDS: false,
      pageLabel: true,
      showFullMessage:false,
      imageName:"",
      regenrateCaptcha:0,
      otp: {
        modal:false,
        value:["", "", "", "","",""],
        message:"", 
        isVerified:false,
        emailVerifyError:"",
        otpVerifyError:""
      },
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.processApiResponse(message);
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleEmailVerification(apiRequestCallId, responseJson)
   
    // Customizable Area End
  }

  // Customizable Area Start

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleValidationApiResponse(responseJson:any,message:any) {
    if (responseJson && this.validationApiCallId === message.id) {
      this.arrayholder = responseJson.data;
  
      if (this.arrayholder && this.arrayholder.length !== 0) {
        const regexData = this.arrayholder[0];
  
        this.passwordReg = regexData.password_validation_regexp
          ? new RegExp(regexData.password_validation_regexp)
          : null;
  
        this.setState({
          passwordHelperText: regexData.password_validation_rules || ''
        });
  
        this.emailReg = regexData.email_validation_regexp
          ? new RegExp(regexData.email_validation_regexp)
          : null;
      }
    }
  }
  
  handleCreateAccountApiResponse(responseJson:any, errorResponse:any,message:any) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
  
    if (apiRequestCallId === this.createAccountApiCallId) {
      if (!responseJson.errors) {
        const msg: Message = new Message(
          getName(MessageEnum.AccoutResgistrationSuccess)
        );

        msg.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );

        this.send(msg);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }
  
  handleAddUserDetailsApiResponse(responseJson:any,message:any) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
  
    if (apiRequestCallId === this.addUserDetailsApiId) {

      if(responseJson.errors && responseJson.errors[0]==="User name has already been taken"){
        this.setState({userIdErrorMsg:"Username taken. Please choose another.", userIdError:true});
      }else if(responseJson.errors && responseJson.errors[0].account==="Email has been taken"){
        this.setState({emailIdErrorMsg:"Email ID taken. Please choose another.", emailIdError:true});
      }
      this.setState({
        disableUserButton: false,
        registrationCompleted: responseJson.meta ? true : false,
        registrationFail: responseJson.meta ? false : true
      });
    }
  }
  
  handleRoleListApiResponse(responseJson:any,message:any) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
  
    if (apiRequestCallId === this.roleListId && responseJson) {
      console.log(": getRoleList :", responseJson);
      this.setState({ roleList: responseJson.data || [] });
    }
  }
  
  handleCreateOrgApiResponse(responseJson:any,message:any) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
  
    if (apiRequestCallId === this.createOrgApiID && responseJson) {
      console.log("getBlock data: createOrgApiID ::", responseJson);
      if (responseJson.data) {
        setStorageData("orgID", responseJson.data.id);
        this.props.navigation.navigate("DigitalSignature");
      }
    }
  }
  
  processApiResponse(message:any) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
  
    this.handleValidationApiResponse(responseJson,message);
    this.handleCreateAccountApiResponse(responseJson, errorResponse,message);
    this.handleAddUserDetailsApiResponse(responseJson,message);
    this.handleRoleListApiResponse(responseJson,message);
    this.handleCreateOrgApiResponse(responseJson,message);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg?.test(email) || false;
  }

  navigationUser(){
    if(this.state.emailIdError){
      this.props.navigation.navigate("OrganisationDetails");
    }
    this.setState({ registrationFail: false });
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg?.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  async componentDidMount() {
    super.componentDidMount();
    this.setState({
      emailId:  await getStorageData("orgEmailID"),
    })
    await this.getRoleList();
  }

  componentDidUpdate = (prevProps:Props, prevState:S) => {
    
    const {
      userId,
      userPwd,
      reTypePwd,
      telePhoneNo,
      userCaptcha,
      userIdError,
      userPwdError,
      reTypePwdError,
      userCaptchaError,
      altTelePhoneNoError,
      telePhoneNoError,
      disableUserButton,
      orgName,
      orgAddress,
      orgCountry,
      orgPinCode,
      orgEmailID,
      orgTown,
      orgTelephoneNo,
      orgNameError,
      orgAddressError,
      orgTownError,
      orgPinCodeError,
      orgCountryError,
      orgTelephoneNoError,
      orgAlternateTelephoneNoError,
      orgEmailIDError,
      orgNextButtonDisable
    } = this.state;
    
    const areRequiredFieldsNotEmpty = [userId, userPwd, reTypePwd, telePhoneNo,userCaptcha].every(field => field);

    const areOrgRequiredFieldsNotEmpty = [orgName,
      orgAddress,
      orgCountry,
      orgPinCode,
      orgEmailID,
      orgTown,
      orgTelephoneNo].every(field => field);

          if (disableUserButton && areRequiredFieldsNotEmpty && !telePhoneNoError &&  !reTypePwdError && !userCaptchaError && !userIdError && !userPwdError  && !altTelePhoneNoError) {
      this.setState({disableUserButton: false})
      return  false;
    } else if (!disableUserButton && (!areRequiredFieldsNotEmpty || userIdError || userPwdError || reTypePwdError || userCaptchaError || altTelePhoneNoError || telePhoneNoError)) {
      this.setState(prev=>({
        disableUserButton: true,
        regenrateCaptcha:prev.regenrateCaptcha+1
      }))
      return  true;
    }


    if (orgNextButtonDisable && areOrgRequiredFieldsNotEmpty && !orgNameError &&  !orgAddressError && !orgTownError && !orgPinCodeError && !orgCountryError  && !orgTelephoneNoError&& !orgAlternateTelephoneNoError  && !orgEmailIDError) {
      this.setState({orgNextButtonDisable: false})
      return  false;
    } else if (!orgNextButtonDisable && (!areOrgRequiredFieldsNotEmpty || orgNameError || orgAddressError || orgTownError || orgPinCodeError || orgCountryError  || orgTelephoneNoError || orgAlternateTelephoneNoError  || orgEmailIDError)) {
      this.setState({orgNextButtonDisable: true})
      return  true;
    }


  }
  addDigitalSignature = () => {
    const { pageLabel, addDigitalSignature, uploadIcon } = this.state;
    if (pageLabel && addDigitalSignature) {
      this.setState({
        pageLabel: false,
        addDigitalSignature: false,
        uploadIcon: true
      });
    } else if (uploadIcon) {
      this.setState({
        pageLabel: true,
        uploadIcon: false,
        verifiedDS: true
      });
    }
  };
  onChange = (imageList: any, index: any) => {
    console.log("imageList :", imageList, index);
    if (imageList) {
      this.setState({ digitalImage: imageList[0].dataURL,imageName: imageList[0].file.name, showImage: true });
    }
  };
  validateSelectType =()=>{
    if (this.state.selectedApplicant === "") {
      this.setState({ applicatTypeError: "Selection Required" });
    } else {
      this.setState({ applicatTypeError: "" });
    }
  }
  setCaptcha=(value:any)=>{
    console.log("setCaptcha value ::",value);
    
    this.setState({captcha:value})
  }
  handleNavigate = (value: any, form?: any) => {
    console.log(
      "signature :1",
      this.state,
      form === "signature" && !this.state.verifiedDS && this.state.uploadIcon,
      form === "signature" && this.state.uploadIcon,
      form === "signature" && this.state.pageLabel && this.state.addDigitalSignature
    );
    if (form === "SelectApplicant" && this.state.selectedApplicant === "") {
      this.setState({ applicatTypeError: "Selection Required" });
    } else if (
      form === "signature" &&
      this.state.pageLabel &&
      this.state.addDigitalSignature
    ) {
      this.setState({
        pageLabel: false,
        addDigitalSignature: false,
        uploadIcon: true
      });
    }
    else if (form === "signature" && this.state.uploadIcon) {
      this.setState({ verifiedDS: true, uploadIcon: false ,pageLabel:true});
      console.log("signature :2", this.state.uploadIcon);
    } else {
      this.props.navigation.navigate(value);
    }
  }

  handleBackNavigate = () => {
    if (this.state.pageLabel && this.state.addDigitalSignature) {
      this.props.navigation.navigate("OrganisationDetails");
    } else if (this.state.uploadIcon) {
      this.setState({
        pageLabel: true,
        addDigitalSignature: true,
        uploadIcon: false
      });
    } else if (this.state.verifiedDS) {
      this.setState({
        verifiedDS: false,
        uploadIcon: true,
        pageLabel:false
      });
    }
  };
  getActionForRead=()=>{
    return !this.state.showFullMessage ? "read more)" : " read less)";
  }
  getFieldInputValidation=()=>{
    if (this.state.showFullMessage){
      return "(Password must be at least 8 characters, no more than 15 characters, and must include at least one special character, one capital letter, one small letter, and one number"
    }else{
      return "(Password must be at least 8 characters, no more than 15 characters, and must include at least one "
    } 
  }
  handleReadMoreClick=()=>{
    this.setState({showFullMessage:!this.state.showFullMessage})
  }
  submitUserDetails = () => {
    console.log(": onSubmitForm :");

    const formData = {
      userId: this.state.userId,
      userPwd: this.state.userPwd,
      reTypePwd: this.state.reTypePwd,
      telePhoneNo: this.state.telePhoneNo,
      emailId: this.state.emailId,
      userCaptcha: this.state.userCaptcha,
      altTelePhoneNo: this.state.altTelePhoneNo
    };

    const errors = this.validateForm(formData);

    if (this.noValidationErrors(errors)) {
      this.addUserDetails();
    }
    this.setState(errors);
  };

  validateForm(formData: any) {
    const errors: any = {
      userIdError: this.validateRequired(
        formData.userId,
        "User ID is required."
      ),
      userPwdError: this.validatePassword(formData.userPwd),
      reTypePwdError: this.validateRetypePassword(
        formData.reTypePwd,
        formData.userPwd
      ),
      altTelePhoneNoError: this.validateAltTelephoneNumber(
        formData.altTelePhoneNo
      ),
      telePhoneNoError: this.validateTelephone(formData.telePhoneNo),
      emailIdError: this.validateEmail(formData.emailId),
      userCaptchaError: this.validateCaptcha(formData.userCaptcha)
    };

    return errors;
  }

  validateRequired(value: string, errorMessage: string) {
    if (value === "") {
      return errorMessage;
    }

    if (value.length < 5 || value.length > 16) {
      return "User ID must be between 5 and 16 characters.";
    }

    if (!/^[a-zA-Z0-9]+$/.test(value)) {
      return "User ID must contain only alphanumeric characters.";
    }

    return "";
  }

  validatePassword(password: string) {
    if (password === "") {
      return "Password is required.";
    } else if (password.length < 8 || password.length > 15) {
      return "Password must be between 8 and 15 characters.";
    } else if (!/[A-Z]/.test(password)) {
      return "Password must contain at least one capital letter.";
    } else if (!/[a-z]/.test(password)) {
      return "Password must contain at least one lowercase letter.";
    } else if (!/\d/.test(password)) {
      return "Password must contain at least one number.";
    } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/.test(password)) {
      return "Password must contain at least one special character.";
    }

    return "";
  }

  validateRetypePassword(reTypePwd: string, userPwd: string) {
    if (reTypePwd === "") {
      return "Please re-enter your password.";
    } else if (reTypePwd !== userPwd) {
      return "Passwords do not match.";
    }

    return "";
  }

  validateAltTelephoneNumber(altTelePhoneNo: string) {
    let numberWithoutPlus = altTelePhoneNo;
    if (typeof altTelePhoneNo === "string") {
      numberWithoutPlus = altTelePhoneNo.startsWith("+")
        ? altTelePhoneNo.slice(1)
        : altTelePhoneNo;
    }
    if (altTelePhoneNo !== "" && !/^\+?\d+$/.test(altTelePhoneNo)) {
      return "Telephone number must contain only numeric digits.";
    } else if (
      altTelePhoneNo !== "" &&
      (numberWithoutPlus.length < 9 || numberWithoutPlus.length > 14)
    ) {
      return "Invalid number";
    }

    return "";
  }

  validateTelephone(telePhoneNo: string) {
    let numberWithoutPlus = telePhoneNo;
    if (typeof telePhoneNo === "string") {
      numberWithoutPlus = telePhoneNo.startsWith("+")
        ? telePhoneNo.slice(1)
        : telePhoneNo;
    }
    if (telePhoneNo === "") {
      return "Telephone number is required.";
    } else if (!/^\+?\d+$/.test(telePhoneNo)) {
      return "Telephone number must contain only numeric digits.";
    } else if (numberWithoutPlus.length < 9 || numberWithoutPlus.length > 14) {
      return "Telephone number must be between 9 and 14 digits.";
    }

    return "";
  }

  validateEmail(emailId: string) {
    // A safer email validation regex
    const emailPattern = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;

    if (emailId === "") {
      return "Email address is required.";
    } else if (!emailPattern.test(emailId)) {
      return "Invalid email.";
    }

    return "";
  }

  validateCaptcha(userCaptcha: string) {
    if (userCaptcha === "") {
      return "Captcha is required.";
    } else if (userCaptcha !== this.state.captcha) {
      return "Invalid Captcha.";
    }

    return "";
  }

  noValidationErrors(errors: any) {
    return Object.values(errors).every(error => error === "");
  }
  onSubmitForm = () => {
    console.log(": onSubmitForm :");

    const formData = {
      orgName: this.state.orgName,
      orgAddress: this.state.orgAddress,
      orgTown: this.state.orgTown,
      orgPinCode: this.state.orgPinCode,
      orgCountry: this.state.orgCountry,
      orgTelephoneNo: this.state.orgTelephoneNo,
      orgAlternateTelephoneNo: this.state.orgAlternateTelephoneNo,
      orgEmailID: this.state.orgEmailID,
      orgFaxNumber: this.state.orgFaxNumber
    };

    const errors = this.validateOrgForm(formData);

    this.setState(prevState => ({
      ...prevState,
      ...errors
    }));

  
    if (Object.keys(errors).length > 0 || this.state.orgAddressError) {
      return;
    }

    // Continue with form submission logic here...

    this.createOrganization();
  };
  createOrganization = async () => {
    console.log("createOrganization :1");

    const header = {
      "Content-Type": "application/json"
    };

    const {
      orgName,
      orgAddress,
      orgEmailID,
      orgTelephoneNo,
      orgAlternateTelephoneNo,
      orgTown,
      orgPinCode,
      orgCountry,
      orgCountryName,
      orgFaxNumber
    } = this.state;

    await setStorageData('orgEmailID',orgEmailID)

    const orgObj = {
      data: {
        attributes: {
          email: orgEmailID,
          name: orgName,
          address: orgAddress,
          phone_no: orgTelephoneNo,
          alternate_no: orgAlternateTelephoneNo,
          city: orgTown,
          pincode: orgPinCode,
          country_id: orgCountry,
          org_country:orgCountryName,
          fax_no: orgFaxNumber
        }
      }
    };

    this.apiCall({
      setApiCallId: "createOrgApiID",
      header,
      method: "POST",
      endPoint: configJSON.createOrganizationEndPoint,
      body: JSON.stringify(orgObj)
    });
  };
  isValueEmpty = (value: any) => {
    return value.trim() === "";
  };
  validateOrgForm = (formData: any): OrgFormErrors => {
    const errors: OrgFormErrors = {};

    const validateField = (
      value: string,
      errorKey: string,
      emptyError: string,
      lengthError: string | null = null,
      pattern: RegExp | null = null,
      alphanumericOnly: boolean = false // New parameter for alphanumeric validation
    ) => {
      if (this.isValueEmpty(value)) {
        errors[errorKey] = emptyError;
      } else if (errorKey === "orgPinCodeError" && !configJSON.alphaNumeric.test(value)
      ) {
        errors[errorKey] = "Only alphanumeric characters are allowed.";
      } else if (errorKey === "orgPinCodeError" &&
      (value.length < 4 || value.length > 6)) {
        // Check for only numbers
        errors[errorKey] = "Pincode must be between 4 and 6 digit.";
      } else if (
        errorKey !== "orgPinCodeError" &&
        lengthError &&
        (value.length < 9 || value.length > 14)
      ) {
        errors[errorKey] = lengthError;
      } else if (
        errorKey !== "orgAddressError" &&
        alphanumericOnly &&
        !configJSON.alphaNumeric.test(value)
      ) {
        errors[errorKey] = "Only alphanumeric characters are allowed.";
      } else if (pattern && !pattern.test(value)) {
        errors[errorKey] = "Invalid Input";
      } else if (
        ["orgTelephoneNoError", "orgAlternateTelephoneNoError"].includes(
          errorKey
        ) &&
        !/^\+?\d+$/.test(value)
      ) {
        errors[errorKey] =
          "Telephone number must contain only 9 to 14 digits and an optional '+' symbol.";
      }
    };

    validateField(
      formData.orgName,
      "orgNameError",
      "This field cannot be left empty",
      null,
      null,
      false// Apply alphanumeric validation
    );

    validateField(
      formData.orgAddress,
      "orgAddressError",
      "This field cannot be left empty",
      null,
      null,
      false // Apply alphanumeric validation
    );
    validateField(
      formData.orgTown,
      "orgTownError",
      "This field cannot be left empty",
      null,
      null,
      true // Apply alphanumeric validation
    );
    validateField(
      formData.orgPinCode,
      "orgPinCodeError",
      "This field cannot be left empty",
      "Pincode must be between 4 and 6 digits"
    );
    validateField(formData.orgCountry, "orgCountryError", "Selection Required");
    validateField(
      formData.orgTelephoneNo,
      "orgTelephoneNoError",
      "This field cannot be left empty",
      "Telephone number must be between 9 and 14 digits"
    );
    formData.orgAlternateTelephoneNo.trim() !== "" &&
      validateField(
        formData.orgAlternateTelephoneNo,
        "orgAlternateTelephoneNoError",
        "This field cannot be left empty",
        "Altelephone number must be between 9 and 14 digits"
      );
    validateField(
      formData.orgEmailID,
      "orgEmailIDError",
      "This field cannot be left empty",
      null,
      /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/
    );

    return errors;
  };
  getRoleList = async () => {
    const header = {
      "Content-Type": "application/json"
    };
    console.log(": getRoleList :");
    
    this.apiCall({
      setApiCallId: "roleListId",
      header,
      method: "GET",
      endPoint: configJSON.roleListEndPoint
    });
  };
  addUserDetails = async () => {
    const header = {
      "Content-Type": "application/json"
    };

    this.setState({disableUserButton:true})

    const {
      userId,
      userPwd,
      emailId,
      telePhoneNo,
      altTelePhoneNo
    } = this.state;

    const roleIDFromStorage = await getStorageData("SelectedRoleID");
    const orgId = await getStorageData("orgID");

    // Check if the value is not null before parsing
    const role_id =
      roleIDFromStorage !== null ? parseInt(roleIDFromStorage, 10) : 0; // Default value is 0, adjust as
    const org_Id = orgId !== null ? parseInt(orgId, 10) : 0; // Default value is 0, adjust as needed

    // Now you can use role_id safely, knowing it's a number

    const userObj = {
      data: {
        type: "email_account",
        attributes: {
          email: emailId,
          password: userPwd,
          user_name: userId,
          role_id: role_id,
          phone_number: telePhoneNo,
          full_phone_number: altTelePhoneNo,
          organization_id: org_Id
        }
      }
    };
    this.apiCall({
      setApiCallId: "addUserDetailsApiId",
      header,
      method: "POST",
      endPoint: configJSON.addUserDeatils,
      body: JSON.stringify(userObj)
    });
  };
  apiCall = async (data: any) => {

    const { setApiCallId, header, endPoint, method, body } = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (setApiCallId==='addUserDetailsApiId'){
      this.addUserDetailsApiId = requestMessage.messageId;
    }else if (setApiCallId==='roleListId'){
      this.roleListId = requestMessage.messageId;
    }else if (setApiCallId === "createOrgApiID") {
      this.createOrgApiID = requestMessage.messageId;
    }
    else if(setApiCallId === "verifyEmailApiId"){
      this.verifyEmailApiId = requestMessage.messageId;
    }  
    else if(setApiCallId === "verifyOtpApiId"){
      this.verifyOtpApiId = requestMessage.messageId
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleChangePassword = (e:any) => {
    const password = e.target.value
    if (!password.includes(" ")) {
      this.setState(prev=>({
        userPwd: password,
        userPwdError: this.validatePassword(password),
        reTypePwdError:password===prev.reTypePwd?"":prev.reTypePwdError
      }));
    }
  }
  
  handleRePassword = (e: any) => {
    const password = e.target.value
    if (!password.includes(" ")) {
      this.setState({
        reTypePwd: password,
        reTypePwdError:this.validateRetypePassword(
          password,
          this.state.userPwd
        )
      });
    }
  }

  checkItEmpty=(value:any, fieldName:any)=>{
    if(this.isValueEmpty(value)){
      return `${fieldName} required.`
    }
    return "";
  }

  handleOrgDetails=(value:any)=>{
    console.log("handleOrgName::",value,this.validateAlphaNumeric(value));
   
    if(this.validateAlphaNumeric(value)){
       return "Special characters are not allowed in the input."
    }
  }

  validateAlphaNumeric=(state:string) => {
    if (!this.isValueEmpty(state) && !configJSON.alphaNumeric.test(state)) {
      return true;
    }
    this.setState({
      orgNextButtonDisable:false
    });
    return false;
  }

  handleClose = () => {
    this.setState({otp:{...this.state.otp,modal:false}})
  };

  handleEmailVerification=(id:string,responseJson:any)=>{
  
    if(this.verifyEmailApiId===id) {
      if(responseJson.status){
        this.setState({otp:{...this.state.otp,message:responseJson.message,modal:true,emailVerifyError:""}})
      }else{
        this.setState({otp:{...this.state.otp,emailVerifyError:responseJson.error}})
      }
    }
    
    if(this.verifyOtpApiId===id) {
      if(responseJson.status){
        this.setState({otp:{...this.state.otp,message:responseJson.message,otpVerifyError:"",isVerified:true,modal:false}})
      }else{
        this.setState({otp:{...this.state.otp,otpVerifyError:responseJson.error}})
      }
    }
  }

  disableOTPButton=()=>{
    return this.state.otp.value.join("").length===6?true:false
  }

  handleTelephone = (e: any) => {
    this.setState({
      orgTelephoneNo: e.target.value,
      orgTelephoneNoError: !/^(?:\+)?\d{9,14}$/g.test(e.target.value) ?
        "Telephone number must contain only 9 to 14 digits and an optional '+' symbol."
        : ""
    });
  }
  handleUserTelephone = (e: any) => {
    this.setState({
      telePhoneNo: e.target.value,
      telePhoneNoError: !/^(?:\+)?\d{9,14}$/g.test(e.target.value) ?
        "Telephone number must contain only 9 to 14 digits and an optional '+' symbol."
        : ""
    });
  }

  handleUserAlternateTelephone = (e: any) => {
    this.setState({
      altTelePhoneNo: e.target.value,
      altTelePhoneNoError: !/^(?:\+)?\d{9,14}$/g.test(e.target.value) ?
        "Telephone number must contain only 9 to 14 digits and an optional '+' symbol."
        : ""
    });
  }

  handleAlternateTelephone = (e: any) => {
    this.setState({
      orgAlternateTelephoneNo: e.target.value,
      orgAlternateTelephoneNoError: !/^(?:\+)?\d{9,14}$/g.test(e.target.value) ?
        "Telephone number must contain only 9 to 14 digits and an optional '+' symbol."
        : ""
    });
  }

  handleOtpModal=()=>{
    this.verifyEmail()
    this.setState(prev=>({otp:{...prev.otp,modal:false}}))
  }

  handleChange = (index: number, e: any) => {
    const { value } = e.target;
    if (value.length <= 1) {
      const otp = [...this.state.otp.value];
      otp[index] = value;
      this.setState({ otp: { ...this.state.otp, value: otp,otpVerifyError:"" } });
      setTimeout(() => {
        if (value && this.otpInputRefs[index + 1]) {
          this.otpInputRefs[index + 1].focus();
        }else{
          this.otpInputRefs[index].focus();
        }
      }, 0)
    }
  };

  handleKeyUp = (index: number, e: any) => {
    const BACKSPACE_KEY = 8;
    const DELETE_KEY = 46;

    if (
      (e.keyCode === BACKSPACE_KEY || e.keyCode === DELETE_KEY) &&
      !e.target.value &&
      this.otpInputRefs[index - 1]
    ) {
      setTimeout(() => {
        this.otpInputRefs[index - 1].focus();
      }, 0)
    }
  };

  showVerfiedText = (isVerified:boolean)=>{
    return isVerified ? "Verified" : "Verify"  
  }

  checkNextBtnDisabled = ()=>{
    return !this.state.otp.isVerified||this.state.orgNextButtonDisable 
  }

  verifyEmail = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const payload={
        "data":
          {
              "attributes":{
              "email":this.state.orgEmailID,
              }
          }
      }

    this.apiCall({
      setApiCallId: "verifyEmailApiId",
      header,
      method: "POST",
      endPoint: configJSON.verifyEmailEndpoint,
      body: JSON.stringify(payload)
    });
  }

  verifyOTP = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const payload={
        "data":
          {
            "attributes":{
              "email":this.state.orgEmailID,
              "otp": this.state.otp.value.join("")
              }
          }
      }

    this.apiCall({
      setApiCallId: "verifyOtpApiId",
      header,
      method: "POST",
      endPoint: configJSON.otpVerifyEndPoint,
      body: JSON.stringify(payload)
    });
  }
  // Customizable Area End
}
