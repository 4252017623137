import React from "react";
// Customizable Area Start
import {
  Typography,
  TextField,
  Grid,
  Dialog,
  DialogContent,
  Box
} from "@material-ui/core";
import MyNavbar from "../../../components/src/Navbar";
import { updatedUserProfileSuccess } from "../src/assets";
import UserProfileBasicController, {
  Props
} from "./UserProfileBasicController.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles, createStyles } from "@material-ui/styles";
import { ipoImage, logoImage } from "../../customform/src/assets";
import Autocomplete from "@material-ui/lab/Autocomplete";
// Customizable Area End


export class UserProfileBasic extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  validationReturn(id: number) {
    if (id === 1) {
      if (this.state.personalPincodeValidate) {
        return <Typography className={this.props.classes.errorMsg}>Invalid PinCode</Typography>;
      }
    } else if (id === 2) {
      if (this.state.personalTelephoneValidate) {
        return (
          <Typography className={this.props.classes.errorMsg}>
            Telephone number must contain only 9 to 14 digits and an optional '+' symbol.
          </Typography>
        );
      }
    } else if (id === 3) {
      if (this.state.nameValidation) {
        return (
          <Typography className={this.props.classes.errorMsg}>
            {this.state.nameValidationText}
          </Typography>
        );
      }
    } else if (id === 4) {
      if (this.state.organizationalPincodeValidate) {
        return <Typography className={this.props.classes.errorMsg}>Invalid Pincode</Typography>;
      }
    } else if (id === 5) {
      if (this.state.organizationalNumberValidate) {
        return (
          <Typography className={this.props.classes.errorMsg}>
            Organisation Phone number must contain only 9 to 14 digits and an optional '+' symbol.
          </Typography>
        );
      }
    }else if (id === 6) {
      if (this.state.organizationalFaxValidate) {
        return (
          <Typography className={this.props.classes.errorMsg}>
            Invalid Fax number 
          </Typography>
        );
      }
    }
  }

  buttonControll() {
    console.log("this.disableSaveButton(): ",this.disableSaveButton())
    return this.state.editState ? (
      <button
        className={this.disableSaveButton() ? this.props.classes.ButtonDisableUD:this.props.classes.actionButtonNew}
        data-test-id="saveButtonActive"
        id="nextButton"
        disabled={this.disableSaveButton()}
        onClick={() => {
          this.updateProfileData();
        }}
      >
        Save
      </button>
    ) : (
      <button
        className={this.props.classes.actionButtonNew}
        data-test-id="editButton"
        id="nextButton"
        onClick={() => {
          this.editStateChange(1);
        }}
      >
        Edit
      </button>
    );
  }

  organizationState(states: any) {
    if (this.state.organizationCountry === "India") {
      return (
        <Autocomplete
          id="organizationState"
          options={states}
          value={this.state.organizationState}
          data-test-id="organizationalState"
          className={this.props.classes.formAutocomplete}
          getOptionLabel={option => option}
          popupIcon={<ExpandMoreIcon />}
          onChange={(e, value) => {
            this.setState({
              organizationState: value ? value : ""
            });
          }}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="Select State"
              variant="outlined"
              fullWidth
              id="renderInput"
              inputProps={{
                ...params.inputProps
              }}
            />
          )}
          fullWidth
        />
      );
    } else {
      return (
        <>
          <TextField
            data-test-id="organizationalState1"
            variant="outlined"
            value={this.state.organizationState}
            InputProps={{
              classes: {
                input: this.props.classes.textFieldInputStyle,
              },
            }}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const text = event.target.value
              this.setState({ organizationState: text }, () => {
                this.validateAlphanumeric(text, 'organizationState')
              });
            }}
          />
          {
            this.state.error['organizationState'] && this.errorHandler('organization city')
          }
        </>
      );
    }
  }

  personalState(states: any) {
    if (this.state.personalCountry === "India") {
      return (
        <Autocomplete
          id="State"
          options={states}
          value={this.state.personalState}
          data-test-id="personalState"
          //className="form-input-row-left-auto"
          className={this.props.classes.widthValue}
          getOptionLabel={option => option}
          popupIcon={<ExpandMoreIcon />}
          onChange={(e, value) => {
            this.setState({
              personalState: value ? value : ""
            });
          }}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="Select State"
              variant="outlined"
              id="renderInput"
              inputProps={{
                ...params.inputProps
              }}
            />
          )}
        />
      );
    } else {
      return (
        <>
          <TextField
            data-test-id="personalState1"
            fullWidth
            variant="outlined"
            InputProps={{
              classes: {
                input: this.props.classes.textFieldInputStyle,
              },
            }}
            value={this.state.personalState}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const text = event.target.value
              this.setState({ personalState: text }, () => {
                  this.validateAlphanumeric(text, 'personalState')
                });
            }}
          />
          {
            this.state.error['personalState'] && this.errorHandler('State')
          }
        </>
      )
    }
  }

  personalCountryChange(value: any) {
    this.setState({
      personalCountry: value ? value : ""
    });
  }

  organizationCountryChange(value: any) {
    this.setState({
      organizationCountry: value ? value : ""
    });
  }

  errorHandler=(title:string) => {
    return (
      <Typography className={this.props.classes.errorMsg}>
      {`Invalid ${title}`}
      </Typography>
    );
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const countries = [
      "Afghanistan",
      "Albania",
      "Algeria",
      "Andorra",
      "Angola",
      "Antigua and Barbuda",
      "Argentina",
      "Armenia",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bhutan",
      "Bolivia",
      "Bosnia and Herzegovina",
      "Botswana",
      "Brazil",
      "Brunei",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cabo Verde",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Central African Republic",
      "Chad",
      "Chile",
      "China",
      "Colombia",
      "Comoros",
      "Congo",
      "Democratic Republic of the Congo",
      "Costa Rica",
      "Côte d'Ivoire",
      "Croatia",
      "Cuba",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Ethiopia",
      "Fiji",
      "Finland",
      "France",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Greece",
      "Grenada",
      "Guatemala",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Honduras",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Ireland",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "North Korea",
      "South Korea",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "North Macedonia",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Micronesia",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Morocco",
      "Mozambique",
      "Myanmar",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Qatar",
      "Romania",
      "Russia",
      "Rwanda",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Vincent and the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome and Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan",
      "Suriname",
      "Eswatini",
      "Sweden",
      "Switzerland",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      "Timor-Leste",
      "Togo",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Vatican City",
      "Venezuela",
      "Vietnam",
      "Zimbabwe"
    ];

    const states = [
      "Andaman and Nicobar Islands",
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chandigarh",
      "Chhattisgarh",
      "Dadra and Nagar Haveli",
      "Daman and Diu",
      "Delhi",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jammu and Kashmir",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Lakshadweep",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Puducherry",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal"
    ];
    return (
      //Merge Engine DefaultContainer

      <Box className={this.props.classes.pageContainerUD}>
        <Box className={this.props.classes.logoContainerUD}>
            <img src={logoImage} alt="Logo" className={this.props.classes.logoUD} />
            <img src={ipoImage} alt="Ipo-Logo" className={this.props.classes.logoUD} />
          </Box>
        <Box className={this.props.classes.outerContainerUD}>
          <Box className={this.props.classes.innerContainerUD}>
            <MyNavbar
              selectedTab={8}
              alertIcon={false}
              profileView={true}
              navigation={this.props.navigation}
            />
            <div className={this.props.classes.subHeaderBox}>
              <Typography className={this.props.classes.subHeaderText}>
                PERSONAL DETAILS
              </Typography>
            </div>
            <Dialog
              open={this.state.updateSucess}
              fullWidth
              className={this.props.classes.dialogModalUD}
              onClose={() => {
                this.setState({ updateSucess: false });
              }}
            >
              <DialogContent className={this.props.classes.dilogContain}>
                <img
                  src={updatedUserProfileSuccess}
                  alt=""
                  className={this.props.classes.iconSize}
                />
                <Typography
                  className={this.props.classes.dialogMsg}
                >
                  {this.state.updateSuccessMessage}
                </Typography>
              </DialogContent>
            </Dialog>
            <Grid
              container
              direction="column"
              justifyContent="space-around"
              alignItems="flex-start"
            >
              {this.state.editState ? (
                <Grid item xs={12} className={this.props.classes.fridStyle}>
                  <Grid
                    container
                    className={this.props.classes.gridContainer}
                  >

                    <Grid item xs={4} md={3} className={this.props.classes.personalInfoTitleBox}>
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                        User ID:
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={3} className={this.props.classes.pesonalGridContainer}>
                      <TextField
                        data-test-id="personalUserId"
                        className={this.props.classes.notEditable}
                        InputProps={{
                          classes: {
                            input: this.props.classes.notEditable,
                          },
                        }}
                        fullWidth
                        disabled
                        variant="outlined"
                        value={this.state.userId}
                      />
                    </Grid>
                    
                    <Grid item xs={4} md={3} className={this.props.classes.personalInfoTitleBoxSecond}>
                      <Typography
                        className={this.props.classes.personalInfoTitleBoxText1}
                      >
                        Alternative <br />
                        Contact No:
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={3} className={this.props.classes.pesonalGridContainer}>
                      <TextField
                        data-test-id="personalAlternativeContact"
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          classes: {
                            input: this.props.classes.textFieldInputStyle,
                          },
                        }}
                        value={this.state.alternativeContactNumber}
                        onChange={event => {
                          this.setState({
                            alternativeContactNumber: event.target.value
                          }, () => {
                            this.validatePhoneNumber(
                              this.state.alternativeContactNumber,
                              -1
                            );
                          });
                        }}
                      />
                      {this.state.alternativeContactValidation &&
                        <Typography className={this.props.classes.errorMsg}>
                          Alternative number must contain only 9 to 14 digits and an optional '+' symbol.
                        </Typography>}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={this.props.classes.gridContainer}
                  >
                    <Grid item xs={4} md={3} className={this.props.classes.personalInfoTitleBox}>
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                        Applicant Type:
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={3} className={this.props.classes.pesonalGridContainer}>
                      <TextField
                        data-test-id="applicantType"
                        disabled
                        fullWidth
                        variant="outlined"
                        value={this.state.applicantType}
                        InputProps={{
                          classes: {
                            input: this.props.classes.notEditable,
                          },
                        }}
                        // className={this.props.classes.notEditable}
                      />
                    </Grid>
                    <Grid item xs={4} md={3} className={this.props.classes.personalInfoTitleBoxSecond}>
                      <Typography
                        className={this.props.classes.personalInfoTitleBoxText1}
                      >
                        Address For <br />
                        Service:
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={3} className={this.props.classes.pesonalGridContainer}>
                      <TextField
                        data-test-id="addressForService"
                        // className={this.props.classes.textFieldHight}
                        InputProps={{
                          classes: {
                            input: this.props.classes.textFieldInputStyle,
                          },
                        }}
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        value={this.state.addressForService}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const text = event.target.value
                          this.setState({
                            addressForService: text
                          }, () => {
                            this.validateAlphanumeric(text, 'addressForService')
                          });
                        }}
                      />
                      {
                        this.state.error['addressForService'] && this.errorHandler('Address for service')
                      }
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={this.props.classes.gridContainer}
                  >
                    <Grid item xs={4} md={3} className={this.props.classes.personalInfoTitleBox}>
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                        Name:
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={3} className={this.props.classes.pesonalGridContainer}>
                      <TextField
                        data-test-id="personalName"
                        fullWidth
                        variant="outlined"
                        value={this.state.name}
                        InputProps={{
                          classes: {
                            input: this.props.classes.textFieldInputStyle,
                          },
                        }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          this.setState({ name: event.target.value }, () => {
                            this.validateNameNew(this.state.name);
                          });
                        }}
                      />
                      {this.validationReturn(3)}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={this.props.classes.gridContainer}
                  >
                    <Grid item xs={4} md={3} className={this.props.classes.personalInfoTitleBox}>
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                        State:
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={3} className={this.props.classes.pesonalGridContainer}>
                      {this.personalState(states)}
                    </Grid>
                    <Grid item xs={4} md={3} className={this.props.classes.personalInfoTitleBox}>
                      <Typography
                        className={this.props.classes.personalInfoTitleBoxText1}
                      >
                        City:
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={3} className={this.props.classes.pesonalGridContainer}>
                      <TextField
                        data-test-id="personalCity"
                        fullWidth
                        variant="outlined"
                        value={this.state.personalCity}
                        InputProps={{
                          classes: {
                            input: this.props.classes.textFieldInputStyle,
                          },
                        }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const text = event.target.value
                          this.setState({ personalCity:  text}, () => {
                            this.validateAlphanumeric(text, 'personalCity')
                          });
                      }}
                    />
                    {
                      this.state.error['personalCity'] && this.errorHandler('City')
                    }
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    className={this.props.classes.gridContainer}
                  >
                    <Grid item xs={4} md={3} className={this.props.classes.personalInfoTitleBox}>
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                        Pin Code:
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={3} className={this.props.classes.pesonalGridContainer}>
                      <TextField
                        data-test-id="personalPinCode"
                        fullWidth
                        variant="outlined"
                        value={this.state.personalPinCode}
                        InputProps={{
                          classes: {
                            input: this.props.classes.textFieldInputStyle,
                          },
                        }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          this.setState(
                            { personalPinCode: event.target.value },
                            () => {
                              this.validatePinCode(
                                this.state.personalPinCode,
                                1
                              );
                            }
                          );
                        }}
                      />
                      {this.validationReturn(1)}
                    </Grid>
                    <Grid item xs={4} md={3} className={this.props.classes.personalInfoTitleBox}>
                      <Typography
                        className={this.props.classes.personalInfoTitleBoxText1}
                      >
                        Country:
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={3} className={this.props.classes.pesonalGridContainer}>
                      <Autocomplete
                        id="Country"
                        value={this.state.personalCountry}
                        options={countries}
                        data-test-id="personalCountry"
                        className={this.props.classes.widthValue}
                        popupIcon={<ExpandMoreIcon />}
                        getOptionLabel={option => option}
                        onChange={(e, value) => {
                          this.personalCountryChange(value);
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder="Select Country"
                            variant="outlined"
                            fullWidth
                            id="renderInput"
                            inputProps={{
                              ...params.inputProps
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={this.props.classes.gridContainer}
                  >
                    <Grid item xs={4} md={3} className={this.props.classes.personalInfoTitleBox}>
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                        Telephone Number:
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={3} className={this.props.classes.pesonalGridContainer}>
                      <TextField
                        data-test-id="personalTelephone"
                        fullWidth
                        variant="outlined"
                        value={this.state.personalTelephoneNo}
                        InputProps={{
                          classes: {
                            input: this.props.classes.textFieldInputStyle,
                          },
                        }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          this.setState(
                            { personalTelephoneNo: event.target.value },
                            () => {
                              this.validatePhoneNumber(
                                this.state.personalTelephoneNo,
                                1
                              );
                            }
                          );
                        }}
                      />
                      {this.validationReturn(2)}
                    </Grid>
                    <Grid item xs={4} md={3} className={this.props.classes.personalInfoTitleBox}>
                      <Typography
                        className={this.props.classes.personalInfoTitleBoxText1}
                        
                      >
                        Email ID:
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={3} className={this.props.classes.pesonalGridContainer}>
                      <TextField
                        data-test-id="personalEmail"
                        InputProps={{
                          classes: {
                            input: this.props.classes.notEditable,
                          },
                        }}
                        disabled
                        fullWidth
                        variant="outlined"
                        value={this.state.emailId}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12} className={this.props.classes.girdStyle}>
                  <Grid
                    container
                    className={this.props.classes.gridContainerView}
                  >
                    <Grid item xs={6} md={2} className={this.props.classes.pesonalGridContainerView}>
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                        User ID:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3} className={this.props.classes.pesonalGridContainerView}>
                      <Typography className={this.props.classes.personalValueText}>
                        {this.state.userId}
                      </Typography>
                    </Grid>
                    <Grid item className={this.props.classes.pesonalGridContainerView} xs={6} md={2}>
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                        Alternative <br />
                        Contact No:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} className={this.props.classes.pesonalGridContainerView}>
                      <Typography className={this.props.classes.personalValueText}>
                        {this.state.alternativeContactNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={this.props.classes.gridContainerView}
                  >
                    <Grid item className={this.props.classes.pesonalGridContainerView} xs={6} md={2}>
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                        Applicant Type:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3} className={this.props.classes.pesonalGridContainerView}>
                      <Typography className={this.props.classes.personalValueText}>
                        {this.state.applicantType}
                      </Typography>
                    </Grid>
                    <Grid item className={this.props.classes.pesonalGridContainerView} xs={6} md={2}>
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                        Address For <br />
                        Service:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={4}
                      className={this.props.classes.pesonalGridContainerView}
                    >
                      <Typography className={this.props.classes.personalValueText}>
                        {this.state.addressForService}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={this.props.classes.gridContainerView}
                  >
                    <Grid
                      item
                      className={this.props.classes.pesonalGridContainerView}
                      xs={6}
                      md={2}
                    >
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                        Name:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className={this.props.classes.pesonalGridContainerView}
                      xs={6} md={3}
                    >
                      <Typography className={this.props.classes.personalValueText}>
                        {this.state.name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className={this.props.classes.pesonalGridContainerView}
                      xs={6}
                      md={2}
                    >
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className={this.props.classes.pesonalGridContainerView}
                      xs={6} md={4}
                    >
                      <Typography className={this.props.classes.personalValueText}>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={this.props.classes.gridContainerView}
                  >
                    <Grid item className={this.props.classes.pesonalGridContainerView} xs={6} md={2}>
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                        State:
                      </Typography>
                    </Grid>
                    <Grid item className={this.props.classes.pesonalGridContainerView} xs={6} md={3}>
                      <Typography className={this.props.classes.personalValueText}>
                        {this.state.personalState}
                      </Typography>
                    </Grid>
                    <Grid item className={this.props.classes.pesonalGridContainerView} xs={6} md={2}>
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                        City:
                      </Typography>
                    </Grid>
                    <Grid item className={this.props.classes.pesonalGridContainerView} xs={6} md={4}>
                      <Typography className={this.props.classes.personalValueText}>
                        {this.state.personalCity}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={this.props.classes.gridContainerView}
                  >
                    <Grid item className={this.props.classes.pesonalGridContainerView} xs={6} md={2}>
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                        Pin Code:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3} className={this.props.classes.pesonalGridContainerView}>
                      <Typography className={this.props.classes.personalValueText}>
                        {this.state.personalPinCode}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={2} className={this.props.classes.pesonalGridContainerView}>
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                        Country:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} className={this.props.classes.pesonalGridContainerView}>
                      <Typography className={this.props.classes.personalValueText}>
                        {this.state.personalCountry}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={this.props.classes.gridContainerView}
                  >
                    <Grid item className={this.props.classes.pesonalGridContainerView} xs={6} md={2}>
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                        Telephone number:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3} className={this.props.classes.pesonalGridContainerView}>
                      <Typography className={this.props.classes.personalValueText}>
                        {this.state.personalTelephoneNo}
                      </Typography>
                    </Grid>
                    <Grid item className={this.props.classes.pesonalGridContainerView} xs={6} md={2}>
                      <Typography className={this.props.classes.personalInfoTitleBoxText1}>
                        Email ID:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} className={this.props.classes.pesonalGridContainerView}>
                      <Typography className={this.props.classes.personalValueText}>
                        {this.state.emailId}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <div className={this.props.classes.subHeaderBox}>
              <Typography className={this.props.classes.subHeaderText}>
                 ORGANISATION DETAILS
              </Typography>
            </div>
            <Grid
              container
              className={this.props.classes.orgDeatilsView}
            >
              {this.state.editState ? (
                <>
                  <Grid item className={this.props.classes.mainGridView}>
                    <Grid className={this.props.classes.userDetailsField}>
                      <Typography className={this.props.classes.organizationDetailsTitleText1}>
                          Organisation Name:
                        </Typography>
                      <Grid item className={this.props.classes.textFieldStyle}>
                        <TextField
                          data-test-id="organizationalName"
                          variant="outlined"
                          value={this.state.organizationName}
                          InputProps={{
                          classes: {
                            input: this.props.classes.textFieldInputStyle,
                          },
                        }}
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const text=event.target.value
                            this.setState({
                              organizationName: text}, () => {
                                this.validateAlphanumeric(text, 'organizationName')
                              });
                          }}
                        />
                        {
                          this.state.error['organizationName'] && this.errorHandler('organization name')
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={this.props.classes.mainGridView}>
                    <Grid className={this.props.classes.userDetailsField}>
                        <Typography className={this.props.classes.organizationDetailsTitleText1}>
                          Organisation Address:
                        </Typography>
                      <Grid item className={this.props.classes.textFieldStyle}>
                        <TextField
                          data-test-id="organizationalAddress"
                          variant="outlined"
                          value={this.state.organizationAddress}
                          InputProps={{
                          classes: {
                            input: this.props.classes.textFieldInputStyle,
                          },
                        }}
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const text=event.target.value
                            this.setState({
                              organizationAddress: text}, () => {
                                this.validateAlphanumeric(text, 'organizationAddress')
                              });
                          }}
                        />
                        {
                          this.state.error['organizationAddress'] && this.errorHandler('organization address')
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={this.props.classes.mainGridView}>
                    <Grid className={this.props.classes.userDetailsField}>
                        <Typography className={this.props.classes.organizationDetailsTitleText1}>
                          Organisation City:
                        </Typography>
                      <Grid item className={this.props.classes.textFieldStyle}>
                        <TextField
                          data-test-id="organizationalCity"
                          variant="outlined"
                          value={this.state.organizationCity}
                          InputProps={{
                          classes: {
                            input: this.props.classes.textFieldInputStyle,
                          },
                        }}
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const text=event.target.value
                            this.setState({
                              organizationCity: text
                            }, () => {
                              this.validateAlphanumeric(text, 'organizationCity')
                            });
                        }}
                      />
                      {
                        this.state.error['organizationCity'] && this.errorHandler('organization city')
                      }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={this.props.classes.mainGridView}>
                    <Grid className={this.props.classes.userDetailsField}>
                        <Typography className={this.props.classes.organizationDetailsTitleText1}>
                          Organisation State:
                        </Typography>

                      <Grid item className={this.props.classes.textFieldStyle}>
                        {this.organizationState(states)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={this.props.classes.mainGridView}>
                    <Grid className={this.props.classes.userDetailsField}>
                        <Typography className={this.props.classes.organizationDetailsTitleText1}>
                          Organisation Country:
                        </Typography>
                      <Grid item className={this.props.classes.textFieldStyle}>
                        <Autocomplete
                          id="organizationCountry"
                          options={countries}
                          value={this.state.organizationCountry}
                          className={this.props.classes.formAutocomplete}
                          data-test-id="organizationalCountry"
                          getOptionLabel={option => option}
                          popupIcon={<ExpandMoreIcon />}
                          onChange={(e, value) => {
                            this.organizationCountryChange(value);
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              placeholder="Select Country"
                              variant="outlined"
                              fullWidth
                              id="renderInput"
                              inputProps={{
                                ...params.inputProps
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={this.props.classes.mainGridView}>
                    <Grid className={this.props.classes.userDetailsField}>
                        <Typography className={this.props.classes.organizationDetailsTitleText1}>
                          Pin Code:
                        </Typography>
                      <Grid item className={this.props.classes.textFieldStyle}>
                        <TextField
                          data-test-id="organizationalPinCode"
                          variant="outlined"
                          value={this.state.organizationPinCode}
                          InputProps={{
                          classes: {
                            input: this.props.classes.textFieldInputStyle,
                          },
                        }}
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            this.setState(
                              { organizationPinCode: event.target.value },
                              () => {
                                this.validatePinCode(
                                  this.state.organizationPinCode,
                                  2
                                );
                              }
                            );
                          }}
                        />
                        {this.validationReturn(4)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={this.props.classes.mainGridView}>
                    <Grid className={this.props.classes.userDetailsField}>
                        <Typography className={this.props.classes.organizationDetailsTitleText1}>
                          Organisation Phone:
                        </Typography>
                      <Grid item className={this.props.classes.textFieldStyle}>
                        <TextField
                          data-test-id="organizationalPhone"
                          variant="outlined"
                          value={this.state.organizationPhone}
                          InputProps={{
                          classes: {
                            input: this.props.classes.textFieldInputStyle,
                          },
                        }}
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            this.setState(
                              { organizationPhone: event.target.value },
                              () => {
                                this.validatePhoneNumber(
                                  this.state.organizationPhone,
                                  2
                                );
                              }
                            );
                          }}
                        />
                        {this.validationReturn(5)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={this.props.classes.mainGridView}>
                    <Grid className={this.props.classes.userDetailsField}>
                        <Typography className={this.props.classes.organizationDetailsTitleText1}>
                          Organisation Fax:
                        </Typography>
                      <Grid item className={this.props.classes.textFieldStyle}>
                        <TextField
                          data-test-id="organizationalFax"
                          variant="outlined"
                          value={this.state.organizationFax}
                          InputProps={{
                          classes: {
                            input: this.props.classes.textFieldInputStyle,
                          },
                        }}
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            this.setState({
                              organizationFax: event.target.value })
                          }}
                        />
                        {this.validationReturn(6)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={this.props.classes.mainGridView}>
                    <Grid className={this.props.classes.userDetailsField}>
                        <Typography className={this.props.classes.organizationDetailsTitleText1}>
                          Website:
                        </Typography>

                      <Grid item className={this.props.classes.textFieldStyle}>
                        <TextField
                          data-test-id="organizationalWebsite"
                          variant="outlined"
                          value={this.state.organizationWebsite}
                          InputProps={{
                          classes: {
                            input: this.props.classes.textFieldInputStyle,
                          },
                        }}
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            this.setState({
                              organizationWebsite: event.target.value
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Box style={{width:"100%"}}>
                  <Box display={"flex"} padding={"20px"}>
                    {/* <Grid container className={this.props.classes.viewUserDeatils}> */}
                      {/* <Grid item className={this.props.classes.organizationDetailsTitle}> */}
                        <Typography className={this.props.classes.organizationDetailsTitleText1}>
                          Organisation Name:
                        </Typography>
                      {/* </Grid> */}
                      {/* <Box display={"flex"} padding={"20px"}> */}
                        <Typography className={this.props.classes.personalValueText}>
                          {this.state.organizationName}
                        </Typography>
                      {/* </Grid> */}
                    {/* </Grid> */}
                  </Box>
                  <Box display={"flex"} padding={"20px"}>
                    {/* <Grid container className={this.props.classes.viewUserDeatils}> */}
                      {/* <Grid item className={this.props.classes.organizationDetailsTitle}> */}
                        <Typography className={this.props.classes.organizationDetailsTitleText1}>
                          Organisation Address:
                        </Typography>
                      {/* </Grid>
                      <Box display={"flex"} padding={"20px"}> */}
                        <Typography className={this.props.classes.personalValueText}>
                          {this.state.organizationAddress}
                        </Typography>
                      {/* </Grid>
                    </Grid> */}
                  </Box>
                  <Box display={"flex"} padding={"20px"}>
                    {/* <Grid container className={this.props.classes.viewUserDeatils}>
                      <Grid item className={this.props.classes.organizationDetailsTitle}> */}
                        <Typography className={this.props.classes.organizationDetailsTitleText1}>
                          Organisation City:
                        </Typography>
                      {/* </Grid>
                      <Box display={"flex"} padding={"20px"}> */}
                        <Typography className={this.props.classes.personalValueText}>
                          {this.state.organizationCity}
                        </Typography>
                      {/* </Grid>
                    </Grid> */}
                  </Box>
                  <Box display={"flex"} padding={"20px"}>
                    {/* <Grid container className={this.props.classes.viewUserDeatils}>
                      <Grid item className={this.props.classes.organizationDetailsTitle}> */}
                        <Typography className={this.props.classes.organizationDetailsTitleText1}>
                          Organisation State:
                        </Typography>
                      {/* </Grid>
                      <Box display={"flex"} padding={"20px"}> */}
                        <Typography className={this.props.classes.personalValueText}>
                          {this.state.organizationState}
                        </Typography>
                      {/* </Grid>
                    </Grid> */}
                  </Box>
                  <Box display={"flex"} padding={"20px"}>
                    {/* <Grid container className={this.props.classes.viewUserDeatils}>
                      <Grid item className={this.props.classes.organizationDetailsTitle}> */}
                        <Typography className={this.props.classes.organizationDetailsTitleText1}>
                          Organisation Country:
                        </Typography>
                      {/* </Grid>
                      <Box display={"flex"} padding={"20px"}> */}
                        <Typography className={this.props.classes.personalValueText}>
                          {this.state.organizationCountry}
                        </Typography>
                      {/* </Grid>
                    </Grid> */}
                  </Box>
                  <Box display={"flex"} padding={"20px"}>
                    {/* <Grid container className={this.props.classes.viewUserDeatils}>
                      <Grid item className={this.props.classes.organizationDetailsTitle}> */}
                        <Typography className={this.props.classes.organizationDetailsTitleText1}>
                          Pin Code:
                        </Typography>
                      {/* </Grid>
                      <Box display={"flex"} padding={"20px"}> */}
                        <Typography className={this.props.classes.personalValueText}>
                          {this.state.organizationPinCode}
                        </Typography>
                      {/* </Grid>
                    </Grid> */}
                  </Box>
                  <Box display={"flex"} padding={"20px"}>
                    {/* <Grid container className={this.props.classes.viewUserDeatils}>
                      <Grid item className={this.props.classes.organizationDetailsTitle}> */}
                        <Typography className={this.props.classes.organizationDetailsTitleText1}>
                          Organisation Phone:
                        </Typography>
                      {/* </Grid>
                      <Box display={"flex"} padding={"20px"}> */}
                        <Typography className={this.props.classes.personalValueText}>
                          {this.state.organizationPhone}
                        </Typography>
                      {/* </Grid>
                    </Grid> */}
                  </Box>
                  <Box display={"flex"} padding={"20px"}>
                    {/* <Grid container className={this.props.classes.viewUserDeatils}>
                      <Grid item className={this.props.classes.organizationDetailsTitle}> */}
                        <Typography className={this.props.classes.organizationDetailsTitleText1}>
                         Organisation Fax:
                        </Typography>
                      {/* </Grid>
                      <Box display={"flex"} padding={"20px"}> */}
                        <Typography className={this.props.classes.personalValueText}>
                          {this.state.organizationFax}
                        </Typography>
                      {/* </Grid>
                    </Grid> */}
                  </Box>
                  <Box display={"flex"} padding={"20px"}>
                    {/* <Grid container className={this.props.classes.viewUserDeatils}>
                      <Grid item className={this.props.classes.organizationDetailsTitle}> */}
                        <Typography className={this.props.classes.organizationDetailsTitleText1}>
                          Website:
                        </Typography>
                      {/* </Grid>
                      <Box display={"flex"} padding={"20px"}> */}
                        <Typography className={this.props.classes.personalValueText}>
                          {this.state.organizationWebsite}
                        </Typography>
                      {/* </Grid>
                    </Grid> */}
                  </Box>
                </Box>
              )}
            </Grid>

            <div className={this.props.classes.buttonsContainer}>{this.buttonControll()}</div>
          </Box>
        </Box>
        <footer className={this.props.classes.footerUP}>
          <p className={this.props.classes.footerTextUP}>
            Guidelines for using NTRP (Bharat Kosh) Payment Gateway{" "}
            <span className={this.props.classes.startColorUP}>Guide</span>
          </p>
        </footer>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const style=createStyles({
  notEditable: {
    backgroundColor: "#DEDEDE",
    fontFamily: "Poppins, sans-serif",
    color: "#64748B", 
    fontSize:"20px",
    borderRadius: "6px",
    '@media (max-width: 1500px)': {
      fontSize: '16px', // Adjust for smaller screens
    },
    '@media (max-width: 1200px)': {
      fontSize: '14px', // Adjust for smaller screens
    },
    '@media (max-width: 1000px)': {
      fontSize: '12px', // Adjust for smaller screens
    },
    '@media (max-width: 800px)': {
      fontSize: '10px', // Adjust for smaller screens
    }
  },
  pageContainerUD: {
    overflowX: 'hidden',
  },
  logoContainerUD: {
      display: "flex",
      backgroundColor: "#ffd4ac",
      justifyContent: "space-between",
      padding: "20px",
      maxWidth: "100%",
      height: "120px",
    },
    logoUD: {
      objectFit: "contain",
      height: "100px",
      padding: "4px",
      '@media (max-width: 900px)': {
        height: "80px",
        padding: "10px",
      },
      '@media (max-width: 600px)': {
        height: "60px",
        padding: "5px",
      },
      '@media (max-width: 300px)': {
        height: "40px",
        padding: "4px",
      }
    },
    girdStyle:{
      width: "100%", padding: "20px"
    },
    girdStyleUserDetails:{
      width: "100%",
      padding: "20px",
      display:"flex",
      flexDirection: "row", 
    },
    fridStyle:{
      width: "100%", padding: "20px 40px 0px 0px"
    },
    textFieldHight:{
      height: "100px",
      fontFamily: 'Poppins, sans-serif',
    },
  outerContainerUD: {
    backgroundColor: '#ffd4ac',
    padding: '20px 180px',
    fontFamily: 'Poppins-Medium, sans-serif',
    marginBottom:"30px",
    '@media (max-width: 1200px)': {
      padding: "30px 120px",
    },
    '@media (max-width: 900px)':{
      padding: "20px 40px",
    },
    '@media (max-width: 600px)':{
      padding: "20px 20px",
    },
    '@media (max-width: 300px)':{
      padding: "20px 10px",
    }
  },
  innerContainerUD: {
    backgroundColor: 'white',
    borderRadius: '20px',
    boxShadow: '5px 10px 8px #888888',
    marginBottom: '5% !important',
  },
  organizationDetailsTitleText1: {
    fontSize: "20px",
    fontWeight:600,
    color: "#000000",
    fontFamily: "Poppins, sans-serif",
    alignSelf: "center",
    marginLeft: "20px",
    flex: "0 0 250px",
    '@media (max-width: 1200px)': {
      flex: "0 0 10px",
      fontSize: "14px",
      display: "flex",
      alignSelf: "flex-start",
      marginLeft: "10px",
    },
    '@media (max-width: 1500px)': {
      fontSize: '16px', // Adjust for smaller screens
    },
    '@media (max-width: 1000px)': {
      fontSize: '12px', // Adjust for smaller screens
    },
    '@media (max-width: 800px)': {
      fontSize: '10px', // Adjust for smaller screens
    }
  },
  userDetailsField:{
    display:"flex",
    gap:"25px",
    '@media (max-width: 1200px)': {
      flexDirection: "column", 
      gap:"9px",
    },
  },
  buttonsContainer: {
    display: "flex",
    paddingBottom: "50px",
    marginTop: "30px",
    justifyContent: "center", /* Center align the buttons horizontally */
  },
  widthValue:{
    width: "100%",
    fontFamily: "Poppins, sans-serif",
    '& .MuiAutocomplete-input': {
      fontSize: '20px',
      color: '#64748B',
      fontFamily: "Poppins, sans-serif",
      '@media (max-width: 1500px)': {
        fontSize: '16px', // Adjust for smaller screens
      },
      '@media (max-width: 1200px)': {
        fontSize: '14px', // Adjust for smaller screens
      },
      '@media (max-width: 1000px)': {
        fontSize: '12px', // Adjust for smaller screens
      },
      '@media (max-width: 800px)': {
        fontSize: '10px', // Adjust for smaller screens
      }
    },
  },
  dialogModalUD: {
    "& .MuiPaper-root": {
      borderRadius: "15px",
      padding: "20px",
      maxWidth: "500px",
    },
    '@media (max-width: 900px)': {
      "& .MuiPaper-root": {
        maxHeight: "300px", 
      },
    },
    '@media (max-width: 600px)': {
      "& .MuiPaper-root": {
        maxHeight: "200px", 
      },
    },
    '@media (max-width: 300px)': {
      "& .MuiPaper-root": {
        maxHeight: "100px", 
      },
    }
  },
  textFieldInputStyle:{
    color: "#64748B",
    fontFamily: "Poppins, sans-serif !important",
    fontSize:"20px",
    fontWeight:400,
    '@media (max-width: 1500px)': {
      fontSize: '16px', // Adjust for smaller screens
    },
    '@media (max-width: 1200px)': {
      fontSize: '14px', // Adjust for smaller screens
    },
    '@media (max-width: 1000px)': {
      fontSize: '12px', // Adjust for smaller screens
    },
    '@media (max-width: 800px)': {
      fontSize: '10px', // Adjust for smaller screens
    }
  },

  textFieldStyle:{
    width: "72%",
    marginRight:"10px",
    '@media (max-width: 1200px)': {
      width: "100%", // Adjust for smaller screens
    },
  },
  iconSize:{
    height: "90px",
    width: "90px"
  },
  dialogMsg:{
    fontWeight: 500,
    fontSize: "25px",
    marginTop:"30px",
    color: "#000000",
    fontFamily: "Poppins, sans-serif",
  },
  dilogContain:{
    textAlign: "center",
    paddingTop: "30px"
  },
  personalInfoTitleBoxSecond: {
    width: '230px',
    height: '45px',
    paddingLeft: '40px',
    paddingRight: '16px',
    paddingTop:"3px",
    '@media (max-width: 1200px)': {
      width: '700px',
      paddingLeft: '20px',
      paddingBottom:"8px",
    }
  },
  personalInfoTitleBox: {
    width: '230px',
    height: '45px',
    paddingLeft: '40px',
    paddingRight: '16px',
    paddingTop:"15px",
    '@media (max-width: 1200px)': {
      width: '700px',
      paddingLeft: '20px',
      paddingBottom:"8px",
    }
  },
  pesonalGridContainer:{
    width: "240px" ,
    Height:"48px",
    paddingBottom:"20px" ,
    fontFamily: "Poppins, sans-serif",
    '@media (max-width: 1200px)': {
      width: "100%", 
      minWidth:"100%",
      marginLeft:"20px",
      marginRight:"20px"
    },
    wordBreak:"break-all",
  },

  pesonalGridContainerView:{
    width: "240px" ,
    height: "auto",
    paddingBottom:"20px" ,
    fontFamily: "Poppins, sans-serif",
    '@media (max-width: 1200px)': {
      marginLeft:"20px",
      width: "200px",
      marginRight:"20px"
    },
    '@media (max-width: 1100px)': {
      marginLeft:"20px",
      width: "160px",
      marginRight:"20px"
    },
    '@media (max-width: 1000px)': {
      marginLeft:"20px",
      marginRight:"20px",
      width: "140px",
    },
  },
  mainGridView:{
    width: "100%",
    marginBottom: "20px",
    paddingInline:"20px"
  },
  subHeaderText: {
    fontFamily: "Poppins, sans-serif",
    fontSize: '25px', // You can adjust this based on your design
    fontWeight: 400,
    alignItems:"center",
    justifyContent:"center",
    display:"flex",
    textAlign:"center",
    color: '#894402',
    padding: '27px', // Adjust padding as needed
    '@media (max-width: 1200px)': {
      fontSize: '22px', // Adjust for smaller screens
    },
  },
  subHeaderBox:{
    height:" 85px",
    backgroundColor: "#FFE6CF",
    width: "100%",
    margin: "50px 0 20px 0"
  },
  footerUP: {
    backgroundColor: "#ffffff",
    padding: "10px",
    height: "70px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  startColorUP: {
    color: "red", // Set the text color to red
  },
    orgDeatilsView:{
    flexDirection:"column",
    justifyContent:"flex-start",
    alignItems:"flex-start",
    marginTop:"50px",
  },
  personalValueText: {
    fontWeight: 400,
    fontSize:"20px",
    fontFamily: "Poppins, sans-serif",
    color: "#64748B",
    wordBreak: "break-all",
    // width:"90%",
    '@media (max-width: 1500px)': {
      fontSize: '16px', // Adjust for smaller screens
    },
    '@media (max-width: 1200px)': {
      fontSize: '14px', // Adjust for smaller screens
    },
    '@media (max-width: 1000px)': {
      fontSize: '12px', // Adjust for smaller screens
    },
    '@media (max-width: 800px)': {
      fontSize: '10px', // Adjust for smaller screens
    }
  },
  organizationDetailsTitle: {
    width: "290px",
    height: "45px",
    marginLeft: "25px",
    display: "flex",
    alignItems: "center"
  },
  errorMsg: {
    fontWeight: 400,
    color: " #FF0000",
    fontFamily: "Poppins, sans-serif",
  },
  footerTextUP: {
    margin: 0,
    fontSize: "20px !important",
    color: "#000000",
    fontWeight: 400,
    lineHeight: "45px !important",
    fontFamily: "Poppins-Medium, sans-serif",
    '@media (max-width: 1200px)': {
      fontSize: "16px",
    },
  },
  formAutocomplete:{
    height: '54px',
    borderRadius: '5px',
    marginRight: '30px',
    fontFamily: 'Poppins, sans-serif',
    color: '#64748B',
    width: "100%",
    marginBottom: '8px',
    '@media (max-width: 1200px)': {
        width: '100%',
        marginRight: 'unset',
    },
    '& .MuiAutocomplete-input': {
      fontSize: '20px',
      color: '#64748B',
      fontFamily: "Poppins, sans-serif",
      '@media (max-width: 1500px)': {
        fontSize: '16px', // Adjust for smaller screens
      },
      '@media (max-width: 1200px)': {
        fontSize: '14px', // Adjust for smaller screens
      },
      '@media (max-width: 1000px)': {
        fontSize: '12px', // Adjust for smaller screens
      },
      '@media (max-width: 800px)': {
        fontSize: '10px', // Adjust for smaller screens
      }
    },
    '& .MuiAutocomplete-root .MuiAutocomplete-option': {
      fontSize: '16px',
      color: '#000000',
      fontFamily: "Poppins, sans-serif"
    },
  },
  ButtonDisableUD: {
    padding: "10px 20px",
    fontSize: "18px",
    fontWeight: "bold",
    borderRadius: "5px",
    margin: "0 10px" /* Add some margin between the buttons */,
    cursor: "pointer",
    minWidth: "300px" /* Minimum width for the button */,
    height: "54px" /* Height for the button */,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    fontFamily: '"Poppins", sans-serif',
    color: "#ffffff", // Text color
    border: "none", // Remove border
    backgroundColor: '#b38961',
    '&:disabled': {
      color: '#FFFFFF !important', // Color when disabled
    },
  },
  actionButtonNew: {
    padding: "10px 20px",
    fontSize: "18px",
    fontWeight: "bold",
    borderRadius: "5px",
    margin: "0 10px" /* Add some margin between the buttons */,
    cursor: "pointer",
    minWidth: "300px" /* Minimum width for the button */,
    height: "54px" /* Height for the button */,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    fontFamily: '"Poppins", sans-serif',
    backgroundColor: "#894402", // Background color
    color: "#ffffff", // Text color
    border: "none", // Remove border
    '@media (max-width: 1200px)': {
      width: "200px",
    }
  },
  gridContainerName: {
    marginBottom: '9px',
    flexDirection: "row",
    marginLeft:"40px",
    '@media (max-width: 1400px)': {
      flexDirection: "column",
  },
  },
  gridContainerView: {
    marginBottom: '9px',
    flexDirection: "row",
    justifyContent:"space-around",
    // marginLeft:"40px",
    alignItems:"flex-start",
    '@media (max-width: 1200px)': {
      flexDirection: "column",
      marginLeft:"40px"
  },
  },
  gridContainerViewInnerSide: {
    flexDirection: "row",
    // justifyContent:"space-around",
    // alignItems:"flex-start",
    '@media (max-width: 1200px)': {
      flexDirection: "column",
  },
  },
  viewUserDeatils:{
    flexDirection: "row",
    alignItems:"center",
    paddingInline:"20px",
    marginBottom:"7px"
  },
  viewUserDeatilsProfile:{
    marginLeft:"20px",
    marginBottom:"7px",
    
  },
  gridContainer: {
    marginBottom: '9px',
    flexDirection: "row",
    '@media (max-width: 1200px)': {
      flexDirection: "column",
  },
  },
  personalInfoTitleBoxText1: {
    fontWeight: 600,
    fontSize:"20px",
    fontFamily: "Poppins, sans-serif",
    '@media (max-width: 1500px)': {
      fontSize: '16px', // Adjust for smaller screens
    },
    '@media (max-width: 1200px)': {
      fontSize: '14px', // Adjust for smaller screens
    },
    '@media (max-width: 1000px)': {
      fontSize: '12px', // Adjust for smaller screens
    },
    '@media (max-width: 800px)': {
      fontSize: '10px', // Adjust for smaller screens
    }
  },
})


export default withStyles(style)(UserProfileBasic)
// Customizable Area End
