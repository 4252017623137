// Customizable Area Start
import React from "react";
import { 
// Customizable Area Start
Button, Grid, Typography, Divider, Box, Checkbox, Dialog, DialogContent,
Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper,
FormControl,MenuItem,Select,TextField
// Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Formik, Form, Field, ErrorMessage, FormikErrors, FormikTouched } from "formik";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { styled } from "@material-ui/styles";
import FormSection from "./FormSection";
import CustomFormController, { Props, Dropdown, configJSON } from "./CustomFormController.web";
import Loader from "../../../components/src/Loader";
import { HighlightOffRounded, CheckBoxOutlineBlankSharp, CheckBoxSharp } from '@material-ui/icons';
import { ipoImage, logoImage } from "../src/assets";
import InputElement from "./InputElement";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Navbar from "../../../components/src/Navbar";

export interface FormData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    section: {
      data: CustomFormSection[];
    };
  };
}

type SavedValues = {
  [fieldName: string]: string | number | boolean;
} | {
  [fieldName: string]: string | number | boolean;
}[]

export interface CustomFormSection {
  id: string;
  type: string;
  saved_values?: SavedValues;
  attributes: {
    section_name: string;
    section_position: number;
    description?: string;
    section_reference: string;
    section_as_array: boolean | null,
    fields: {
      data: CustomFormField[];
    };
  };
}

export interface CustomFormField {
  id: string;
  type: string;
  attributes: {
    id: number;
    field_name: string;
    field_type: "dropdown" | "integer" | "string" | "boolean" | "text";
    required: boolean;
    notes: string;
    min_value: number,
    max_value: number,
    disabled: boolean,
    fixed_filed_name: string;
    place_holder: string,
    default_value?: string;
    row_count?: number,
    values: Array<{
      id: number;
      value: string;
      custom_form_field_id: number;
      created_at: string;
      updated_at: string;
    }>;
    is_alphanumeric: boolean;
    is_gi_name: boolean;
  };
}

export const styles = () =>
  createStyles({
    pageContainer: {
      overflowX: "hidden",
      backgroundColor: "#ffd4ac",
    },
    outerContainer: {
      backgroundColor: "#ffd4ac",
      padding: "30px 200px",
      fontFamily: "Poppins, sans-serif",
      '@media (max-width: 1600px)': {
        padding: "30px 120px",
      },
      '@media (max-width: 900px)': {
        padding: "20px 40px",
      },
      '@media (max-width: 600px)': {
        padding: "20px 20px",
      },
      '@media (max-width: 300px)': {
        padding: "20px 10px",
      }
    },
    innerContainer: {
      backgroundColor: "white",
      borderRadius: "20px",
      boxShadow: "0px 2px 24px 2px #00000033",
      padding: "70px 120px",
      marginBottom: "80px",
      '@media (max-width: 1600px)': {
        padding: "50px 80px",
      },
      '@media (max-width: 900px)': {
        padding: "50px 40px",
      },
      '@media (max-width: 600px)': {
        padding: "50px 20px",
      },
      '@media (max-width: 300px)': {
        padding: "50px 10px",
      }
    },
    heading: {
      fontWeight: 600,
      fontSize: "40px",
      textAlign: "center",
      fontFamily: "Poppins, sans-serif",
      margin: "14px 0",
      '@media (max-width: 1600px)': {
        fontSize: "26px",
        margin: "10px 0",
      },
      '@media (max-width: 900px)': {
        fontSize: "24px",
      },
      '@media (max-width: 600px)': {
        fontSize: "20px",
      },
      '@media (max-width: 300px)': {
        fontSize: "16px",
      }
    },
    description: {
      fontWeight: 600,
      fontSize: "24px",
      textAlign: "center",
      fontFamily: "Poppins, sans-serif",
      margin: "10px 0",
      '@media (max-width: 1600px)': {
        fontSize: "18px",
      },
    },
    submissionContainer: {
      margin: "20px 0",
    },
    submissionText: {
      fontSize: "24px",
      fontFamily: "Poppins, sans-serif",
      '@media (max-width: 1600px)': {
        fontSize: "18px",
      },
      "& .MuiCheckbox-root": {
        padding: "10px 10px 10px 0px"
      }
    },
    noteContainer: {
      margin: "40px 0",
    },
    noteTag: {
      fontWeight: 500
    },
    noteText: {
      color: "#001AFF",
      fontFamily: "Poppins, sans-serif",
      fontSize: "22px",
      '@media (max-width: 1600px)': {
        fontSize: "16px",
      },
    },
    redText: {
      color: "red",
      margin: "0px",
      fontFamily: "Poppins, sans-serif",
    },
    buttonContainer: {
      display: "flex",
      flexWrap: "wrap",
      spacing: "20px",
    },
    button: {
      backgroundColor: "#8A4504",
      color: "white",
      fontWeight: 500,
      borderRadius: "6px",
      width: "100%",
      boxShadow: "0px 2px 4px 1px rgba(0,0,0,0.25)",
      textTransform: "none",
      fontSize: "24px",
      fontFamily: "Poppins, sans-serif",
      '@media (max-width: 1600px)': {
        fontSize: "18px",
      },
      "&:hover": {
        backgroundColor: "8A4504",
      },
      "&.Mui-disabled": {
        opacity: 0.5,
        color: "white"
      }
    },
    buttonOutline: {
      backgroundColor: "white",
      color: "#894402",
      width: "100%",
      fontWeight: 500,
      borderRadius: "6px",
      border: "1px solid #894402",
      boxShadow: "0px 2px 4px 1px rgba(0,0,0,0.25)",
      textTransform: "none",
      fontSize: "24px",
      fontFamily: "Poppins, sans-serif",
      '@media (max-width: 1600px)': {
        fontSize: "18px",
      },
      "&:hover": {
        backgroundColor: "white",
      },
      '&:disabled': {
        opacity: 0.5
      }
    },
    guideLink: {
      color: "#ff0000",
      textDecoration: "none",
    },
    footer: {
      backgroundColor: "#ffffff",
      padding: "10px",
      textAlign: "center",
      height: "70px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    footerText: {
      margin: 0,
      fontSize: "24px",
      color: "#000000",
      fontWeight: 400,
      lineHeight: "45px",
      fontFamily: "Poppins-Medium, sans-serif",
      '@media (max-width: 1600px)': {
        fontSize: "20px",
      },
    },
    addButton: {
      fontFamily: "Poppins-Medium, sans-serif",
      width: "180px",
      fontSize: "24px",
      color: "white",
      textTransform: "none",
      padding: "5px",
      backgroundColor: "#5EAE03",
      "&:hover": {
        backgroundColor: "#5EAE03",
      },
      '@media (max-width: 1600px)': {
        fontSize: "18px",
      },
    },
    closeIconContainer: {
      display: "flex",
      justifyContent: "end",
      margin: "-20px"
    },
    closeIcon: {
      color: "#B8B8B8",
      width: "46px",
      height: "46px",
      '@media (max-width: 1600px)': {
        width: "32px",
        height: "32px",
      },
    },
    checkedIcon: {
      color: "#33CD33"
    },
    buttonLabel: {
      fontFamily: "Poppins, sans-serif",
      fontSize: "24px",
      '@media (max-width: 1600px)': {
        fontSize: "18px",
      },
    },
    buttonPlaceHolder: {
      border: "1px solid #bbbbbb",
      fontSize: "24px",
      fontFamily: "Poppins, sans-serif",
      color: "#bbbbbb",
      padding: "10px",
      '@media (max-width: 1600px)': {
        fontSize: "18px",
      }
    },
    addButtonContainer: {
      padding: "30px 20px",
    },
    tableFormContainer: {
      marginBottom: "30px"
    },
    imageContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    
    tableContainer:{
      borderRadius: "0px"
    },
    image: {
      objectFit: "contain",
      height: "160px",
      padding: "40px",
      '@media (max-width: 1600px)': {
        height: "100px",
        padding: "20px",
      },
      '@media (max-width: 900px)': {
        height: "80px",
        padding: "10px",
      },
      '@media (max-width: 600px)': {
        height: "60px",
        padding: "5px",
      },
      '@media (max-width: 300px)': {
        height: "40px",
        padding: "4px",
      }
    },
    table: {
      '& td': {
        textAlign: 'left',
        fontFamily: "Poppins, sans-serif",
        border: '1px solid #8A4504',
        padding: "20px",
      },
      '& th': {
        border: '1px solid #8A4504',
        textAlign: 'left',
        padding: "20px",
        fontWeight: 600,
        fontSize: "16px",
        backgroundColor: '#fdf3e7', // Light brown header background color
        fontFamily: "Poppins, sans-serif",
      }
    },
    tableButton: {
      borderRadius: "12px 0px 12px 0px",
      fontWeight: 600,
      backgroundColor: "#8A4504",
      textTransform: "none",
      color: "white",
      boxShadow: "0px 2px 4px 1px rgba(0,0,0,0.25)",
      fontFamily: "Poppins, sans-serif",
      "&:hover": {
        backgroundColor: "8A4504",
      },
      "&.MuiButton-text": {
        padding: "2px 20px"
      }
    },
    divider: {
      margin: "0px 20px"
    },
    lastSection: {
      padding: "0px 20px"
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      background: "white",
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      gap:"20px",
      padding: "40px",
      paddingTop: "40px !important",
    },
    message: {
      color: "#894402",
      textAlign: "center",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "20px",
      marginBottom: "10px",
      maxWidth: "75%",
      margin: "0 auto",
    }
  });


type ArrayFormValues = {
  [sectionId: string]: {
    [fieldName: string]: string | number | boolean;
  }[]
}

type ObjectFormValues = {
  [sectionId: string]: {
    [fieldName: string]: string | number | boolean;
  }
}

type FormValues = ArrayFormValues | ObjectFormValues;

type LastSection = {
  [key : string] : boolean
}

const modalStyles = () =>
  createStyles({
    modal: {
      "& .MuiPaper-root": {
        borderRadius: "20px",
        padding: "20px",
        maxWidth: "800px",
      }
    },
    modalHeading: {
      fontSize: "32px",
      fontWeight: 600,
      fontFamily: "Poppins, sans-serif",
      margin: "20px",
      marginBottom: "40px",
      '@media (max-width: 1600px)': {
        fontSize: "24px",
      },
    },
    buttonContainer: {
      margin: "20px 0px"
    },
    button: {
      backgroundColor: "#8A4504",
      color: "white",
      fontWeight: 500,
      borderRadius: "6px",
      width: "100%",
      fontFamily: "Poppins, sans-serif",
      boxShadow: "0px 2px 4px 1px rgba(0,0,0,0.25)",
      textTransform: "none",
      fontSize: "24px",
      '@media (max-width: 1600px)': {
        fontSize: "18px",
      },
      "&:hover": {
        backgroundColor: "8A4504",
      },
      '&:disabled': {
        opacity: 0.5,
      },
      "&.Mui-disabled": {
        opacity: 0.5,
        color: "white"
      }
    },
    buttonOutline: {
      backgroundColor: "white",
      color: "#894402",
      width: "100%",
      fontWeight: 500,
      borderRadius: "6px",
      border: "1px solid #894402",
      boxShadow: "0px 2px 4px 1px rgba(0,0,0,0.25)",
      textTransform: "none",
      fontSize: "24px",
      fontFamily: "Poppins, sans-serif",
      '@media (max-width: 1600px)': {
        fontSize: "18px",
      },
      "&:hover": {
        backgroundColor: "white",
      },
      '&:disabled': {
        opacity: 0.5,
      }
    },
    noteContainer: {
      margin: "30px 0",
    },
    noteTag: {
      fontWeight: 500
    },
    noteText: {
      color: "#001AFF",
      fontFamily: "Poppins, sans-serif",
      fontSize: "22px",
      '@media (max-width: 1600px)': {
        fontSize: "16px",
      },
    },
    redText: {
      color: "red",
      margin: "0px",
      fontFamily: "Poppins, sans-serif",
    },
  });

interface ModalFormValues {
  [fieldName: string]: string | number | boolean;
};

interface MyModalProps extends WithStyles<typeof modalStyles> {
  handleClose: () => void;
  isOpen: boolean;
  setFieldValue: (name: string, values: any) => void,
  values: FormValues,
  sectionId: string
  validationSchema: Yup.ObjectSchema<ModalFormValues>
  initialValues: ModalFormValues;
  section: CustomFormSection,
  editIndex: number | null,
  handleSameAsApplicant: (setFieldValue: (name: string, value: any) => void) => void
}

class MyModal extends React.Component<MyModalProps> {

  render() {
    const { classes, editIndex, sectionId, handleSameAsApplicant } = this.props;
    const values = this.props.values as ArrayFormValues
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.props.handleClose}
        className={classes.modal}
        aria-labelledby="form-dialog-title"
      >
        <Typography className={this.props.classes.modalHeading}>
          {this.props.section.attributes.section_name}
        </Typography>
        <DialogContent>
          <Box >
            <Formik
              initialValues={this.props.initialValues}
              validationSchema={this.props.validationSchema}
              onSubmit={(val) => {
                if (editIndex !== null) {
                  this.props.setFieldValue(
                    sectionId,
                    [...values[sectionId].slice(0, editIndex),
                      val,
                    ...values[sectionId].slice(editIndex + 1)]

                  );
                }
                else {
                  this.props.setFieldValue(
                    sectionId,
                    [...values[sectionId], val]
                  );
                }
                this.props.handleClose();
              }}
            >
              {({ errors, touched, values, isValid, dirty, setFieldValue }) => (
                <Form translate={undefined}>
                  <Box>
                    <Grid container spacing={4}>
                      {this.props.section.attributes.fields.data.map((fData) => {
                        const { fixed_filed_name, required, field_type, place_holder, field_name, disabled, row_count } = fData.attributes
                        return (
                          <InputElement
                            key={fixed_filed_name}
                            fieldType={field_type}
                            error={errors[fixed_filed_name]}
                            label={field_name}
                            name={fixed_filed_name}
                            touched={touched[fixed_filed_name]}
                            fixedName={fixed_filed_name}
                            required={required}
                            rowCount={row_count}
                            options={fData.attributes.values}
                            values={values}
                            placeholder={place_holder}
                            disabled={disabled}
                            handleSameAsApplicant={() => handleSameAsApplicant(setFieldValue)}
                          />
                        );
                      })}
                    </Grid>
                  </Box>
                  <Box className={this.props.classes.noteContainer}>
                    <Typography className={this.props.classes.noteText}>
                      <span className={this.props.classes.noteTag}>Note: {" "}</span>
                      Fields appearing with{" "}
                      <span className={this.props.classes.redText}>
                        *
                      </span>{" "}
                      are mandatory
                    </Typography>
                  </Box>
                  <Grid
                    container
                    spacing={2}
                    className={this.props.classes.buttonContainer}
                  >
                    <Grid item xs={12} sm={2}>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button data-test-id="resetButton" className={this.props.classes.buttonOutline} onClick={this.props.handleClose}>Cancel</Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button data-test-id="SaveButton" type="submit" disabled={!(isValid && dirty)} className={this.props.classes.button}>Add</Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

export const FormModal = withStyles(modalStyles)(MyModal);

const tableSectionStyles = () =>
  createStyles({
    addButtonContainer: {
      padding: "30px 20px",
    },
    addButton: {
      backgroundColor: "#5EAE03",
      fontSize: "24px",
      '@media (max-width: 1600px)': {
        fontSize: "18px",
      },
      width: "180px",
      color: "white",
      textTransform: "none",
      padding: "5px",
      "&:hover": {
        backgroundColor: "#5EAE03",
      },
      fontFamily: "Poppins-Medium, sans-serif",
    },
    buttonLabel: {
      fontSize: "24px",
      '@media (max-width: 1600px)': {
        fontSize: "18px",
      },
      fontFamily: "Poppins, sans-serif",
    },
    buttonPlaceHolder: {
      border: "1px solid #bbbbbb",
      fontSize: "24px",
      '@media (max-width: 1600px)': {
        fontSize: "18px",
      },
      fontFamily: "Poppins, sans-serif",
      color: "#bbbbbb",
      padding: "10px",
    },
    tableContainer: {
      marginBottom: "30px",
    },
    table: {
      '& th': {
        backgroundColor: '#fdf3e7',
        border: '1px solid #8A4504',
        padding: "20px",
        color: "black",
        textAlign: 'center',
        fontWeight: 600,
        fontSize: "16px",
        fontFamily: "Poppins, sans-serif",
      },
      '& td': {
        border: '1px solid #8A4504',
        padding: "20px",
        color: "black",
        textAlign: 'center',
        fontFamily: "Poppins, sans-serif",
      },
    },
    tableButton: {
      borderRadius: "12px 0px 12px 0px",
      backgroundColor: "#8A4504",
      color: "white",
      fontWeight: 600,
      textTransform: "none",
      fontFamily: "Poppins, sans-serif",
      boxShadow: "0px 2px 4px 1px rgba(0,0,0,0.25)",
      "&:hover": {
        backgroundColor: "8A4504",
      },
      "&.MuiButton-text": {
        padding: "2px 20px",
      },
    },
  });

interface TableSectionProps extends WithStyles<typeof tableSectionStyles> {
  section: CustomFormSection;
  arrayValues: ArrayFormValues;
  handleAddButton: () => void;
  handleEditButton: (index: number) => void;
  handleRemoveButton: (index: number) => void;
  isDisabled:any;
}

class TableSelectionComponent extends React.Component<TableSectionProps> {
  render() {
    const { classes, handleAddButton, arrayValues, section, handleEditButton, handleRemoveButton, isDisabled } = this.props;

    return (
      <Grid container className={classes.addButtonContainer} alignItems="center" spacing={4}>
        <Grid item xs={12} sm={5}>
          <Typography className={classes.buttonLabel}>{section.attributes.section_name}</Typography>
        </Grid>
        {!isDisabled&&<Grid item xs={12} sm={7}>
          <Button data-test-id="addTableButton" onClick={handleAddButton} className={classes.addButton}>Add {section.attributes.section_name}</Button>
        </Grid>}
        {arrayValues[section.id]?.length > 0 ? <Grid item xs={12} sm={12}>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                {!isDisabled &&<TableCell><Box></Box></TableCell>}
                  <TableCell><Box>Sr.</Box></TableCell>
                  {section.attributes.fields.data.map((field) => (
                    <TableCell>
                      <Box>{field.attributes.field_name}</Box>
                    </TableCell>
                  ))}
                  {!isDisabled &&<TableCell><Box></Box></TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {arrayValues[section.id].map((sectionOrder, index: number) => (
                  <TableRow>
                    {!isDisabled &&
                      <TableCell>
                        <Button className={classes.tableButton}
                          data-test-id="editButton"
                          onClick={() => {
                            handleEditButton(index)
                          }}>Edit</Button>
                      </TableCell>
                    }
                    <TableCell><Box>{index + 1}</Box></TableCell>
                    {Object.keys(sectionOrder).map((field) => {
                      const sectionFormOrder = sectionOrder as any;
                      return (
                        <TableCell><Box>{sectionFormOrder[field]?.name ? sectionFormOrder[field]?.name : sectionFormOrder[field]}</Box></TableCell>
                      )
                    })}
                    {!isDisabled &&
                      <TableCell>
                        <Button className={classes.tableButton}
                          data-test-id="removeButton"
                          onClick={() => {
                            handleRemoveButton(index)
                          }}>Remove</Button>
                      </TableCell>
                    }
                  </TableRow>
                ))}
                {/* Add more rows with 7 columns as needed */}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid> :
          <Grid item xs={12} sm={12}>
            <Typography className={classes.buttonPlaceHolder}>No {section.attributes.section_name} Added</Typography>
          </Grid>}
      </Grid>
    );
  }
}

const TableSectionElement = withStyles(tableSectionStyles)(TableSelectionComponent);

export class FormTypeSelectionPage extends CustomFormController {
  render() {
    const { classes, navigation } = this.props;
    const { formTypeOptions } = this.state

    return (
      <Box className={classes.pageContainer}>
        <Box className={classes.logoImageContainer}>
          <img src={logoImage} alt="Logo" className={classes.logo} />
          <img src={ipoImage} alt="Ipo-Logo" className={classes.logo} />
        </Box>
        <Box className={classes.outerContainer}>
          <Box className={classes.innerContainer}>
            <Navbar selectedTab={0} navigation={navigation} alertIcon />
            <Box>
              <Typography variant="h4" className={classes.heading}>
                Form Type Selection
              </Typography>
              <Box className={classes.subHeadingContainer}>
                <Typography className={classes.subHeading}>Select Form</Typography>
              </Box>
              <Box className={classes.selectContainer}>
                <Box className={classes.inputContainer}>
                  <Typography className={classes.inputLabel}>Form type:</Typography>
                  <FormControl>
                    <Select
                      variant="outlined"
                      disableUnderline
                      data-test-id="formTypeInput"
                      displayEmpty
                      className={classes.input}
                      value={this.state.selectedFormType}
                      IconComponent={KeyboardArrowDownIcon}
                      onChange={this.navigateToCustomForm}
                      style={{color:!this.state.selectedFormType.length?"#979797":"black"}}
                    >
                      <MenuItem className={classes.formSelectPlaceHolder} value={""}>Select type of form</MenuItem>
                      {formTypeOptions.length > 0
                        && formTypeOptions.map((option) =>
                          <MenuItem
                            key={option.attributes.id}
                            className={classes.menuItem}
                            value={option.attributes.id}>
                            {option.attributes.name}
                          </MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </Box>
                <Box className={classes.inputContainer}>
                  <Typography className={classes.inputLabel}>
                    Application number:
                  </Typography>
                  <TextField
                    onChange={() => { }}
                    variant="outlined"
                    className={classes.input}
                  />
                </Box>
              </Box>
            </Box>
            <Typography className={classes.bottomContent}>
              Note: Application number is applicable only for GI-3A and GI-3B forms.
            </Typography>
            <Box className={classes.navigationLinkContainer}>
              <Box className={classes.navigationLinkButtonContainer}>
                <Button className={classes.navigationLink}>Click to find the right Geographical Indication Form for you</Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <footer className={classes.footer}>
          <Typography className={classes.footerText}>
            Guidelines for using NTRP (Bharat Kosh) Payment Gateway  <a href="#" className={classes.guideLink}>Guide</a>
          </Typography>
        </footer>
      </Box>
    );
  }
}

export const stylesForFormTypeSelection = () =>
  createStyles({
    pageContainer: {
      overflowX: "hidden",
    },
    logoImageContainer: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "#ffd4ac",
      padding: "10px",
      maxWidth: "100%",
      height: "100px",
    },
    logo: {
      maxWidth: "100%",
      objectFit: "contain",
    },
    outerContainer: {
      backgroundColor: "#ffd4ac",
      padding: "4px 160px",
      fontFamily: "Poppins, sans-serif !important",
    },
    innerContainer: {
      backgroundColor: "white",
      borderRadius: "20px",
      boxShadow: "5px 10px 8px #888888",
      marginBottom: "40px"
    },
    heading: {
      fontWeight: 600,
      fontSize: "24px !important",
      color: "#8a4504",
      textAlign: "center",
      fontFamily: 'Poppins, sans-serif !important',
      margin: "20px 0 !important",
      lineHeight: "20px"
    },
    subHeadingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "70px",
      backgroundColor: "#FFE2C7D9",
    },
    subHeading: {
      fontSize: "22px !important",
      fontWeight: 300,
      fontFamily: 'Poppins, sans-serif !important',
    },
    selectContainer: {
      marginBottom: "12%"
    },
    inputLabel: {
      fontSize: "20px !important",
      fontFamily: 'Poppins, sans-serif !important',
    },
    input: {
      width: "380px",
      "& .MuiOutlinedInput-input": {
        padding: "10px",
       },
      "& .MuiInputBase-input": {
        fontSize: "18px",
        fontFamily: "Poppins, sans-serif",
       },
      '& .MuiSvgIcon-root': {
        color: 'black',
    },
    },
    menuItem: {
      fontFamily: "Poppins, sans-serif",
  },
    inputContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      width: "650px",
      margin: "40px auto",
    },
    bottomContent: {
      textAlign: "center",
      fontFamily: 'Poppins, sans-serif !important',
      padding: "20px",
      color: "#001AFF",
    },
    formSelectPlaceHolder: {
      color: "#979797"
    },
    navigationLink: {
      color: "black",
      textDecoration: "underline",
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: "18px",
      '&:hover': {
        opacity: 1,
        textDecoration: "underline",
      },
      textTransform: 'none'

    },
    navigationLinkContainer: {
      paddingBottom: "20px"
    },
    navigationLinkButtonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#FFE2C7D9",
      padding: "4px"
    },
    guideLink: {
      color: "#ff0000",
      textDecoration: "none"
    },
    footer: {
      backgroundColor: "#ffffff",
      padding: "10px",
      textAlign: "center",
      height: "50px !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    footerText: {
      margin: 0,
      fontSize: "20px !important",
      color: "#000000",
      lineHeight: "45px !important",
      fontFamily: "Poppins-Medium, sans-serif !important",
    },
    // Media query for screens less than 900px
    "@media (max-width: 900px)": {
      outerContainer: {
        padding: "20px 50px",
      },
      inputContainer: {
        width: "300px",
        margin: "40px auto",
      },
      input: {
        width: "300px",
      },
      logoImageContainer: {
        height: "80px",
      },
    },
    // Media query for screens less than 600px
    "@media (max-width: 600px)": {
      outerContainer: {
        padding: "20px 30px",
      },
      logoImageContainer: {
        height: "60px",
      },
    },
    // Media query for screens less than 300px
    "@media (max-width: 300px)": {
      outerContainer: {
        padding: "20px 5px",
      },
      inputContainer: {
        width: "200px",
        margin: "40px auto",
      },
      input: {
        width: "200px",
      },
      logoImageContainer: {
        height: "45px",
      },
    }
  });

export const FormTypeSelection =  withStyles(stylesForFormTypeSelection)(FormTypeSelectionPage);
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  ResponseModal = () => {
    return (
      <Dialog
        open={this.state.messageModal.showModal}
        onClose={this.handleMessageModalClose}
        className={this.props.classes.modal}
        aria-labelledby="form-dialog-title"
        data-test-id="ResponseModal"
      >
        
        <DialogContent className={this.props.classes.paper}>
        <Typography className={this.props.classes.message}>
          {this.state.messageModal.message}
        </Typography>
        <img src={this.state.messageModal.icon} width={"60"}/>
        
        <Button className={this.props.classes.button} style={{width:"50%"}} onClick={this.handleMessageModalClose}
        data-test-id="closeBtn">
          {this.state.messageModal.buttonLabel}
        </Button>

        </DialogContent>
      </Dialog>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className={this.props.classes.pageContainer}>
        <Box className={this.props.classes.imageContainer}>
          <img className={this.props.classes.image} src={logoImage} />
          <img className={this.props.classes.image} src={ipoImage} />
        </Box>
        <Box className={this.props.classes.outerContainer}>
          <Box className={this.props.classes.innerContainer}>
            {Object.keys(this.state.formData).length ?
              <>
                <Box className={this.props.classes.closeIconContainer} onClick={this.goBack} data-test-id="goback">
                  <HighlightOffRounded className={this.props.classes.closeIcon} />
                </Box>
                <Typography variant="h4" className={this.props.classes.heading}>
                  {this.state.formData.attributes.name}
                </Typography>
                <Typography variant="h4" className={this.props.classes.description}>
                  {this.state.formData.attributes.description}
                </Typography>

                <Formik
                  validationSchema={this.generateValidationSchema(this.state.formData)}
                  onSubmit={this.handleSubmit}
                  initialValues={this.generateInitialValues(this.state.formData)}
                >
                  {({ errors, touched, resetForm, values, setFieldValue, isValid, dirty }) => {
                    const formErrors = errors as FormikErrors<ObjectFormValues>;
                    const formTouched = touched as FormikTouched<ObjectFormValues>
                    const formValues = values as ObjectFormValues
                    return (
                      <Form translate={undefined}>
                        <Grid container spacing={2}>
                          {this.state.formData.attributes.section.data
                            .filter(section => (section.attributes.section_as_array === false || section.attributes.section_as_array === null))
                            .map((section) =>
                            (<Grid data-test-id='form-section-grid' item xs={12} key={section.id}>
                              <FormSection 
                                data-test-id="form-section"
                                formFields={section}
                                touched={formTouched}
                                errors={formErrors}
                                isDisabled={this.state.isPreview}
                                values={formValues}
                                setFieldValue={setFieldValue}
                                handleSameAsApplicant={() => this.handleSameAsApplicant(section, this.state.formData, formValues, (name: string, value: any) => setFieldValue(`${section.id}.${name}`, value))}
                                resetSameAsApplicant={() => this.resetSameAsApplicant(section, this.state.formData, formValues, (name: string, value: any) => setFieldValue(`${section.id}.${name}`, value))}
                              />
                              <Divider className={this.props.classes.divider} />
                            </Grid>
                            ))}
                        </Grid>
                        <Box className={this.props.classes.tableFormContainer}>
                          {this.state.formData.attributes.section.data
                            .filter(section => section.attributes.section_as_array === true)
                            .map((section) => {
                              const arrayValues = values as ArrayFormValues
                              const { modalId, currentEditIndex, isModalOpen } = this.state.modalData
                              const sectionId = section.id
                              return (
                                <>
                                  <FormModal
                                    data-test-id="modalElement"
                                    editIndex={currentEditIndex}
                                    initialValues={(currentEditIndex !== null && modalId === sectionId) ? arrayValues[section.id][currentEditIndex] : this.generateInitialValuesForModal(section)}
                                    handleSameAsApplicant={(setFieldValue: (name: string, value: any) => void) => this.handleSameAsApplicant(section, this.state.formData, formValues, setFieldValue)}
                                    validationSchema={this.generateValidationSchemaForModal(section)}
                                    section={section}
                                    values={values}
                                    sectionId={section.id}
                                    setFieldValue={setFieldValue}
                                    isOpen={!!(isModalOpen && modalId === sectionId)}
                                    handleClose={() => this.setState({ modalData: { isModalOpen: false, currentEditIndex: null, modalId: "" } })}
                                  />
                                  <TableSectionElement isDisabled={this.state.isPreview} data-test-id="tableSection" arrayValues={arrayValues} section={section}
                                    handleAddButton={() => { this.setState({ modalData: { currentEditIndex: null, isModalOpen: true, modalId: sectionId } }) }}
                                    handleEditButton={(index: number) => {
                                      this.setState({
                                        modalData: {
                                          currentEditIndex: index,
                                          isModalOpen: true,
                                          modalId: sectionId
                                        },
                                      })
                                    }}
                                    handleRemoveButton={(index: number) => {
                                      setFieldValue(
                                        section.id,
                                        arrayValues[section.id].filter((applicant, applicantIndex: number) => applicantIndex !== index)
                                      );
                                    }}
                                  />
                                <Divider className={this.props.classes.divider} />
                                </>
                              )
                            })}
                        </Box>
                        {!this.state.isPreview && <Box className={this.props.classes.lastSection}>
                          <Box className={this.props.classes.submissionContainer}>
                            <Typography className={this.props.classes.submissionText}>
                              <Field
                                as={Checkbox}
                                name="last_section.term_and_conditions"
                                icon={<CheckBoxOutlineBlankSharp color={Boolean((formErrors.last_section?.term_and_conditions && formTouched.last_section?.term_and_conditions)) ? "error" : undefined} />}
                                checkedIcon={<CheckBoxSharp className={this.props.classes.checkedIcon} />}
                                checked={(values["last_section"] as LastSection).term_and_conditions}
                              />
                              I agree that the above information submitted by me are
                              true to my best knowledge.
                              <span className={this.props.classes.redText}>
                                *
                              </span>
                            </Typography>
                            <ErrorMessage name="last_section.term_and_conditions" component="p" className={this.props.classes.redText} />
                          </Box>
                          <Box className={this.props.classes.noteContainer}>
                            <Typography className={this.props.classes.noteText}>
                              <span className={this.props.classes.noteTag}>Note: {" "}</span>
                              Fields appearing with{" "}
                              <span className={this.props.classes.redText}>
                                *
                              </span>{" "}
                              are mandatory
                            </Typography>
                          </Box>
                          <Grid
                            container
                            spacing={4}
                            className={this.props.classes.buttonContainer}
                          >
                            <Grid item xs={12} sm={3}>
                              <Button data-test-id="cancelButton" className={this.props.classes.buttonOutline} onClick={() => this.props.navigation.goBack()}>Cancel</Button>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Button data-test-id="resetButton" disabled={!dirty} className={this.props.classes.button} onClick={() => {
                                resetForm()
                                setFieldValue("last_section.term_and_conditions", false);
                              }}>Reset</Button>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Button className={this.props.classes.button}
                                data-test-id="save-publish-btn"
                                disabled={!(isValid && dirty)}
                                type="submit"
                                onClick={() => { this.setIsPublished(configJSON.markAsDarft) }}>
                                Save
                              </Button>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Button className={this.props.classes.button}
                                data-test-id="save-and-submit"
                                disabled={!(isValid && dirty)}
                                type="submit"
                                onClick={() => {
                                  this.setIsPublished(configJSON.markAsPublished)}}>
                                Save & Submit
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>}
                      </Form>
                    )
                  }}
                </Formik>
              </> : <Loader loading={this.state.loading} />}
          </Box>
        </Box>
        <this.ResponseModal />
        <footer className={this.props.classes.footer}>
          <Typography className={this.props.classes.footerText}>
            Guidelines for using NTRP (Bharat Kosh) Payment Gateway{" "}
            <a href="#" className={this.props.classes.guideLink}>
              Guide
            </a>
          </Typography>
        </footer>
      </Box>
      // Customizable Area End
    );
  }
}

const userTypeList: Dropdown[] = [
  { label: "Tenant", value: "Tenant" },
  { label: "Owner", value: "Owner" },
  { label: "Supervisor", value: "Supervisor" }
];

const ratingList: Dropdown[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" }
];

const genderList: Dropdown[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" }
];

const SubmitButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  padding: "6px 31px",
  "&:hover": {
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  padding: "6px 17px",
  border: "1px solid rgb(98, 0, 238)",
  color: "rgb(98, 0, 238)",
  fontSize: "18px",
  borderRadius: "8px",
  marginRight: "15px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: "white",
    color: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 13px",
  },
});

const webStyle = {
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    display: "flex",
    justifyContent: "center",
    color: "#000",
    alignItems: "center",
    fontSize: "16px",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    color: "#1b3c69",
    fontSize: "30px"
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "end"
  },
  buttonStyle: {
    fontSize: "18px",
    borderRadius: "8px",
    padding: "6px 17px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer"
  },
};
// Customizable Area End
