import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "../assets/helpCentreQA.css"; 
import HelpCentreWebController, { Props } from "./HelpCentreWebController";
import Navbar from "../../../components/src/Navbar";
import Accordion from "../../../components/src/Accordion";
import { leftLogo, rightLogo} from "./assets"
import { createStyles , withStyles} from "@material-ui/styles"


export class HelpCentreQA extends HelpCentreWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { helpCenterFaq } = this.state;
    return (
      <Box className="pageContainer">
        <Box className={this.props.classes.logoContainerFAQ}>
          <img
            src={leftLogo}
            alt="Logo"
            className={this.props.classes.logoFAQ} />
          <img src={rightLogo} alt="Ipo-Logo" className={this.props.classes.logoFAQ} />
        </Box>
        <Box className="outerContainer">
          <Box className="innerContainer">
            <Navbar selectedTab={6} navigation={this.props.navigation}/>
            <Box className="contentContainer">
              <Typography variant="h4" className="headingFaq">
                Frequently Asked Questions
                <Typography display="inline" className="spanFaq">
                  (FAQs)
                </Typography>
              </Typography>
              <Typography variant="h5" className="contentFaq">
                Every region has its claim to fame. Christopher Columbus sailed from
                Europe to chart out a new route to capture the wealth of rich Indian
                spices. English breeders imported Arabian horses to sire Derby
                winners. China silk, Dhaka muslin, Venetian Glass all were much sought
                after treasures. Each reputation was carefully built up and
                painstakingly maintained by the masters of that region, combining the
                best of Nature and Man, traditionally handed over from one generation
                to the next for centuries. Gradually, a specific link between the goods
                and place of production evolved resulting in the growth of geographical
                indications.
              </Typography>
              <Typography variant="h5" className="contentFaq">
                In December 1999, the Parliament had passed the Geographical
                Indications of Goods (Registration and Protection) Act, 1999. This Act
                seeks to provide for the registration and better protection of
                geographical indications relating to goods in India. The Act would be
                administered by the Controller General of Patents, Designs and Trade
                Marks- who is the Registrar of Geographical Indications. The Geographical
                Indications Registry would be located at Chennai.
              </Typography>
              <Box className="instructionsHeadingFaq">
                <Typography variant="h4" className="instructionsHeading">
                  Please follow the following steps to register GI
                </Typography>
                <Typography
                  variant="h5"
                  className="instructionsSubheading"
                >
                  Check The page for Detail Understanding of Geographical
                  Indications Registration Process.
                </Typography>
              </Box>
              {helpCenterFaq.length > 0 && helpCenterFaq.map((faq) => (
                <Accordion key={faq.id} questionNumber={faq.attributes.question_no} question={faq.attributes.question} answer={faq.attributes.answer} />
              ))}
            </Box>
            <Typography className="bottomContent">
              <a className="hyperlinks" href="/TermsConditions">Terms & conditions</a> | <a className="hyperlinks" href="/PrivacyPolicy">Privacy Policy</a> | <a className="hyperlinks" href="#">Copyright</a> | <a className="hyperlinks" href="#">SICLDR</a> | <a className="hyperlinks" href="#">Hyperlinking</a>
              <a className="hyperlinks" href="#">Policy</a> | <a className="hyperlinks" href="#">Accessibility</a> | <a className="hyperlinks" href="#">Archive</a> | <a className="hyperlinks" href="/ContactUs">Contact Us</a> | <a className="hyperlinks" href="#">Help</a> Content Owned,
              updated and maintained by Intellectual Property India, All Rights Reserved.
            </Typography>
          </Box>
        </Box>
        <footer className={this.props.classes.footer}>
          <p className={this.props.classes.footerText}>
            Guidelines for using NTRP (Bharat Kosh) Payment Gateway <a className="guideHypertext" href="#">Guide</a>
          </p>
        </footer>
      </Box>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
const webStyles = createStyles({
  logoFAQ : {
    objectFit: "contain",
    height: "100px",
    padding: "4px",
    '@media (max-width: 900px)': {
      height: "80px",
      padding: "10px",
    },
    '@media (max-width: 600px)': {
      height: "60px",
      padding: "5px",
    },
    '@media (max-width: 300px)': {
      height: "40px",
      padding: "4px",
    }
  },
  logoContainerFAQ: {
    justifyContent: "space-between",
    backgroundColor: "#ffd4ac",
    height: "120px",
    maxWidth: "100%",
    padding: "20px",
    display: "flex",
  },
  footer: {
    backgroundColor: "#ffffff",
    padding: "10px",
    height: "70px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  footerText: {
    margin: 0,
    fontSize: "20px !important",
    color: "#000000",
    fontWeight: 400,
    lineHeight: "45px !important",
    fontFamily: "Poppins-Medium, sans-serif !important",
  },
});
export default withStyles(webStyles)(HelpCentreQA)
// Customizable Area End